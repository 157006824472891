<template>
  <div class="warningBox" v-show="warningShow">
      <img src="@/assets/img/utils/warningIcon.png" alt="" class="warningIcon">
      <label for="msg" id="msg_label" :aria-live='live'>{{warningMsg}}</label>
      <input id="msg" type="hidden">
  </div>
</template>
<script>
export default {
    props: {
        warningShow:Boolean,
        warningMsg:String
    },
    data(){
        return {
            live:'off'
        }
    },
    components: {
        
    },
    async mounted(){
        var that = this;
        
    },
    methods:{
       
    },
    watch: {
        warningShow(val, newval) {
            if(val){
                // $('#msg').focus();
                // $('#msg_label').attr({'aria-live':'polite'})
                this.live = 'polite';
                $('.warningBox').attr({'aria-atomic':true});
            }
        }
    }
}
</script>
<style lang='scss'>
.warningBox{
    width: 100%;
    height: 0.88rem;
    padding: 0.24rem 0.4rem;
    border: 1px solid rgb(255,204,105);
    background: rgb(255,248,234);
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .warningIcon{
        display: block;
        width: 0.4rem;
        height: 0.4rem;
    }
    >label{
        display: inline-block;
        width: 6.05rem;
        font-size: 0.19rem;
        line-height: 0.23rem;
        word-wrap: normal;
        word-break: keep-all;
    }
}
</style>

<template>
  <div class="container">
    <div class="wrap">
        <logo/>
        <div class="content">
            <img src="@/assets/img/forgot/emailPic.png" alt="">
            <span>The email has been sent successfully.<br>Please check your HSBC mailbox.</span>
        </div>
        <div class="btns">
            <button class="btn" @click="back">Back to log on page</button>
        </div>
    </div>
  </div>
</template>

<script>
import logo from '@/components/logo.vue'
export default {
  components:{
      logo
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          
        }
    },
    mounted(){
        var that = this;
        utag_fun(that.$route.name)
    },
    methods:{
        back:function(){
            var that = this;
            that.$router.push({path:'/logon/on'})
        }
    },
}
</script>

<style lang='scss' scoped>
.wrap{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .content{
        width: 100%;
        >img{
            display: block;
            width: 1.2rem;
            height: 0.94rem;
            margin: 0 auto;
        }
        >span{
            display: block;
            width: 100%;
            margin-top: 0.8rem;
            font-size: 0.32rem;
            color: #000000;
            line-height: 0.48rem;
            text-align: center;
        }
    }
    .btns{
      width: 100%;
      padding: 0.4rem;
      box-sizing: border-box;
      position: fixed;
      bottom: 0;
      left: 0;
      .btn{
        width: 100%;
        height: 1.18rem;
        border-radius: 0.02rem;
        font-size: 0.32rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #DB0011;
        color: #ffffff;
        &:active{
              background: #A7140A;
          }
      }
    }
}
</style>

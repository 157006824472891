<template>
  <div class="container">
    <top :backShow='true'/>
    <div class="homeWrap" @scroll="handleScroll($event)">
        <h1 class="title">Latest articles</h1>
        <div class="bannerBox">
            <div class="banner">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <router-link class="swiper-slide" v-for="(item,index) in banner" :key="index" :to="{name:'News',query:{id:item.ad_link}}" aria-live='off'>
                            <img :src="item.ad_code" />
                            <div class="slideBox">
                                <!-- <img src="@/assets/img/home/play.png" alt="Play" class="playIcon"> -->
                                <div class="slideBoxContent">
                                    <h1>{{item.ad_name}}</h1>
                                    <h2>{{item.ad_description}}</h2>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="swiper-pagination-art"></div>
            </div>
        </div>
        <div class="list">
            <router-link class="listItem" v-for="(item,index) in news" :key='index' :to="{name:'News',query:{id:item.id}}">
                <div class="listPic">
                    <img :src="item.image" alt="" width="100%">
                </div>
                <div class="listContent">
                    <a class="listTitle" href="javascript:void(0)">{{item.title}}</a>
                    <a class="listView" href="javascript:void(0)">{{item.description}}</a>
                    <a class="listMore" href="javascript:void(0)">
                        <span >Read more</span>
                        <img src="@/assets/img/home/arrow.png" alt="" class="arrowIcon">
                    </a>
                </div>
            </router-link>
        </div>
    </div>
    <tabbar/>
  </div>
</template>

<script>
import Swiper from 'swiper';
import '../../assets/css/swiper.min.css';
import tabbar from '@/components/tabbar.vue'
import top from '@/components/top.vue'
export default {
    components:{
      tabbar,
      top
    },
    head(){
        return {
            title:''
        }
    },
    created() {
		
	},
    data(){
        return {
          banner:[],
          news:[],
          page:1,
          allpage:1,
        }
    },
    mounted(){
        var that = this;
        that.getData();
        that.$toast.loading({
            message: 'Loading...',
            forbidClick: true,
        });
        utag_fun(that.$route.name)

    },
    methods:{
        handleScroll:function(e){
           var that = this;
           var clientHeight = e.target.clientHeight;
           var scrollTop = e.target.scrollTop;
           var scrollHeight = e.target.scrollHeight;
           if(clientHeight + scrollTop == scrollHeight){
               that.getData()
           }
        },
        bindNews:function(id){
            var that = this;
            that.$router.push({name:'News',query:{id:id}})
        },
        getData:function(){
            var that = this;
            if(that.page > that.allpage){
                return false;
            }
            that.$http.get(that.$api.getNewsData.url, {page:that.page}).then(res => {
                if(res.code == 1){
                    that.banner = res.data.adList;
                    that.news =that.news.concat(res.data.history.lists);
                    that.page++;
                    that.allpage = res.data.history.pages.allpage;
                    that.swiper();
                    that.$nextTick(function(){
                        that.$toast.clear()
                    })
                }else{
                    that.$toast(res.msg);
                }
            })
        },
        swiper:function(){
            var that = this;
            that.$nextTick(function(){
                var mySwiper = new Swiper(".swiper-container",{ 
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    loop:true,
                    // autoplay:4000,
                    pagination: {
                        el: '.swiper-pagination-art',
                        clickable: true,
                    },
                    onSlideChangeEnd: function(){
                           
                                $('.swiper-slide-active').attr({'aria-live':'polite'});
                                $('.swiper-slide-active').focus();
                                $('.swiper-slide-active').siblings().attr({'aria-live':'off'});
                                
                        },
                });
                mySwiper.stopAutoplay()
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                console.log(isiOS)
                if(isiOS){
                     mySwiper.startAutoplay()
                }
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.homeWrap{
    padding: 0.16rem 0.23rem;
    box-sizing: border-box;
    .title{
        width: 100%;
        height: 0.6rem;
        font-size: 0.46rem;
        font-weight: 500;
        line-height: 0.6rem;
    }
    .bannerBox{
        width: 100%;
        .banner{
            width: 100%;
            margin-top: 0.16rem;
            position: relative;
            .swiper-container {
                width: 100%;
                .swiper-slide {
                    width: 6.20rem;
                    height: 3.4rem;
                    position: relative;
                    >img{
                        width: 100%;
                    }
                    .slideBox{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: rgba(0,0,0,0.5);
                        padding: 0.24rem 0.3rem;
                        box-sizing: border-box;
                        .playIcon{
                            display: block;
                            width: 0.6rem;
                            height: 0.6rem;
                            position: absolute;
                            bottom: 0.24rem;
                            right: 0.24rem;
                        }
                        .slideBoxContent{
                            width: 100%;
                            height: 100%;
                            color: #ffffff;
                            >h1{
                                font-size: 0.32rem;
                                line-height: 0.38rem;
                                margin-bottom: 0.12rem
                            }
                            >h2{
                                font-size: 0.19rem;
                                line-height: 0.27rem;
                            }
                        }
                    }
                }
            }
        }

    }
    .list{
        width: 100%;
        margin-top: 0.25rem;
        .listItem{
            width: 100%;
            height: 2.64rem;
            background: #F0F0F0;
            margin-bottom: 0.25rem;
            overflow: hidden;
            display: flex;
            .listPic{
                width: 3.39rem;
                height: 2.64rem;
                overflow: hidden;
            }
            .listContent{
                width: 3.64rem;
                height: 2.64rem;
                padding: 0.16rem 0.24rem;
                box-sizing: border-box;
                position: relative;
                .listTitle{
                    width: 100%;
                    max-height: 0.8rem;
                    line-height: 0.4rem;
                    font-size: 0.24rem;
                    color: #515050;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .listView{
                    width: 100%;
                    height: 0.84rem;
                    margin-top: 0.16rem;
                    font-size: 0.19rem;
                    line-height: 0.28rem;
                    color: #515050;
                    display: -webkit-box;    
                    -webkit-box-orient: vertical;    
                    -webkit-line-clamp: 3;    
                    overflow: hidden;
                }
                .listMore{
                    width: 100%;
                    height: 0.32rem;
                    padding: 0 0.24rem 0.16rem 0.24rem;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    >span{
                        font-size: 0.19rem;
                        color: #000000;
                        font-weight: bold;
                    }
                    .arrowIcon{
                        display: block;
                        width: 0.18rem;
                        height: 0.21rem;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.swiper-pagination-art{
    position: absolute;
    bottom: 0.17rem;
    left: 0;
    height: 0.1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .swiper-pagination-bullet{
        display: block;
        width: 0.1rem;
        height: 0.1rem;
        border-radius: 50%;
        background: rgba(255,255,255,0.48);
        margin: 0 0.04rem;
        opacity: 1;
        &.swiper-pagination-bullet-active{
            background: rgba(255,255,255,1);
        }
    }
}
</style>

<template>
  <div id="app" v-cloak>
    <router-view/>
  </div>
</template>
<script>
import $ from 'jquery'
import utils from "./assets/js/utils.js"
export default {
    head(){
        return {
            title:''
        }
    },
    created() {
      var that = this;
      that.$router.beforeEach((to, from, next) => {
          next();
          window.location.reload()
      });
    },
    data(){
        return {
          
        }
    },
    mounted(){
      
        if (!this._isMobile()) {
          if(window.top === window.self){
            var url = encodeURIComponent(window.location.href);
            window.location.href = utils.host()+'/hfh5/mobile.html?url='+url;
          }
          
        }
        document.body.addEventListener('touchstart', function () {});  
    },
    methods:{
      _isMobile:function(){
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      }
    }
}
</script>
<style lang="scss">
body{
  margin: 0;
}
*{ margin:0px; padding:0px; border:0px none; outline:none;}
input{ outline:none;}
li{ list-style:none;}
img{ border:0 none; vertical-align:top;}
em,i,b{ font-style:normal;}
a{ text-decoration:none; cursor: pointer; color: #000;}
body{
  max-width: 750px;
}
button{
  background: none;
}
h1{
  font-weight: normal;
}
h2{
  font-weight: normal;
}
[v-cloak] {
    display: none !important;
 }
.container {
  margin: 0 auto;
  height: 100vh;
}
.homeWrap{
  position: fixed;
  top: 0.88rem;
  left: 0;
  bottom: 0.98rem;
  right: 0;
  overflow-y: auto;
}
.homeWrapNoTop{
  top: 0 !important;
}
input{ outline:none !important;}
.progress{
        width: 0.07rem;
        height: 100%;
        background:rgb(216,216,216);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        .progressValue{
            width: 100%;
            background: rgb(219,0,17);
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    
</style>

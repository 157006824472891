export default {
    // get，获取登录用户信息
    getUserInfo: {
      url: "/user/info"
    },
    //get,问卷问题列表
    getQuestionList: {
      url: "/user/question_lists"
    },
    //post 提交问卷
    answerCollect:{
      url:"/user/answer_collect"
    },
    //post 注册
    register: {
      url: "/user/register"
    },
    //post 检查并锁定用户名
    checkReg:{
      url:'/validate/check_reg_arg'
    },
    //post 登录
    login:{
      url:"/user/login"
    },
    //post 退出登录
    logout:{
      url:"/user/logout"
    },
    //post 忘记密码
    passwordForget:{
      url:"/user/password_forget"
    },
    //post 忘记用户名
    usernameForget:{
      url:"/user/username_forget"
    },
    //get 活动中心首页
    getActIndex:{
      url:"/activity/index"
    },
    //get 播放页数据
    webcasts:{
      url:"/activity/webcasts"
    },
    //get 文章页数据
    getNewsData:{
      url:"/activity/news"
    },
    //get 文章详情
    articleDetail:{
      url:'/activity/article_detail'
    },
    //get 用户排名
    getRank:{
      url:'/user/rank_lists'
    },
    //post 用户反馈
    feedBack:{
      url:'/user/feedback'
    },
    //get 互动页数据
    getInteraction:{
      url:'/activity/interaction'
    },
    //post 活动报名
    actSign:{
      url:"/activity/signUp"
    },
    //post 取消活动报名
    actWithdraw:{
      url:"/activity/withdraw"
    },
    //get 用户中心数据
    userIndex:{
      url:"/user/index"
    },
    //post 文章点赞
    articleLike:{
      url:'/activity/article_like'
    },
    //post 文章点赞取消
    articleUnLike:{
      url:'/activity/article_unlike'
    },
    //post 更换密码
    passwordChange:{
      url:'/user/password_change'
    },
    //post 上传文件
    upload:{
      url:'/common/upload?ptype=avatar'
    },
    //post 修改个人信息
    profile:{
      url:'/user/profile'
    },
    //get 获取utype
    utype:{
      url:'/user/tiny_info'
    },
    //get 固定页面
    fixedPage:{
      url:'/activity/fixed_page'
    },
    //post 通过ckey验证更换密码
    ckeyPassword:{
      url:'/user/password_change_by_ckey'
    },
    //post 游戏加积分
    goToGame:{
      url:'/game/goToGame'
    }
  } 
<template>
  <div class="container">
    <div class="welcome">
      <img src="@/assets/img/index/background.png" alt="background" class="background">
    </div>
  </div>
</template>
<script>
export default {
  components:{
      
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          
        }
    },
    mounted(){
      var that = this;
      utag_fun(that.$route.name);
      that.getUserInfo();
      
    },
    methods:{
      getUserInfo:function(){
            var that = this;
            that.$http.get(that.$api.getUserInfo.url, {}).then(res => {
                if(res.code == 1){
                  if(res.data.userinfo.utype == 1){
                    setTimeout(() => {
                      that.$router.push({path:'/home'})
                    }, 1000);
                  }else if(res.data.userinfo.utype == 2){
                    setTimeout(() => {
                      that.$router.push({path:'/my/family'})
                    }, 1000);
                  }
                }else{
                  setTimeout(() => {
                    that.$router.push({path:'/logon/welcome'})
                  }, 1000);
                   
                }
            })
        },
    },
}
</script>
<style lang='scss'>
.welcome{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .background{
    display: block;
    width: 100%;
    height: 14.5rem;
  }
}
</style>

<template>
  <div class="container">
    <div class="close" @click="back">
        <img src="@/assets/img/utils/close.png" alt="close" width="100%">
    </div>
    <div class="contact">
        <h1 class="title">Contact us</h1>
        <label class="label" for="content" tabindex="1" ref="newConBox" aria-live='off'>Please contact us for any queries you may have.</label>
        <div class="contactBox" :style="{top:top+'px'}">
            <textarea name="content" id="content" class="contactInput" v-model="content"></textarea>
        </div>
    </div>
    <div class="btns">
        <button class="btn" @click="send" id="send">Send</button>
    </div>
  </div>
</template>

<script>

export default {
    components:{

    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          top:0,
          content:'',
        }
    },
    mounted(){
        var that = this;
        var boxTop = that.$refs.newConBox.getBoundingClientRect().top;
        var boxHeight = that.$refs.newConBox.offsetHeight;
        var height = boxTop + boxHeight;
        that.top = height;
        that.$nextTick(function(){
            $('.label').attr({'aria-live':'polite'})
            $('.label').focus();
        })
        utag_fun(that.$route.name)
    },
    methods:{
        send:function(){
            var that = this;
            if(!that.content){
                return false;
            }
            that.$http.post(that.$api.feedBack.url, {content:that.content,type:'normal'}).then(res => {
                if(res.code == 1){
                     that.$toast.success('Query sent');
                     var type = JSON.parse(that.$utils.getCookie('need_utype')).utype;
                     setTimeout(() => {
                        if(type == 1){
                            that.$router.push({path:'/home'})
                        }
                        if(type == 2){
                            that.$router.push({path:'/my/family'})
                        }  
                     }, 2000);
                     
                }else{
                    that.$toast(res.msg);
                }
            })
        },
        back:function(){
            var that = this;
            that.$router.go(-1);
        }
    },
}
</script>

<style lang='scss' scoped>
.close{
    width: 0.4rem;
    height: 0.4rem;
    position: fixed;
    top: 0.24rem;
    right: 0.24rem;
    z-index: 10;
}
.contact{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 1.98rem;
    padding: 0.8rem 0.4rem 0;
    box-sizing: border-box;
    z-index: 6;
    .title{
        width: 100%;
        line-height: 0.6rem;
        font-size: 0.46rem;
        font-weight: 500;
    }
    .label{
        display: block;
        width: 100%;
        margin-top: 0.24rem;
        line-height: 0.48rem;
        font-size: 0.32rem;
        padding-bottom: 0.24rem;
    }
    .contactBox{
        position: absolute;
        left: 0.4rem;
        bottom: 0;
        right: 0.4rem;
        border: 1px solid #979797;
        box-sizing: border-box;
        .contactInput{
            width: 100%;
            height: 100%;
            padding: 0.12rem;
            box-sizing: border-box;
            font-size: 0.32rem;
            line-height: 0.48rem;
            color: #D1D1D1;
            resize: none;
        }
    }
}
.btns{
    width: 100%;
    padding: 0.4rem;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    .btn{
        width: 100%;
        height: 1.18rem;
        border-radius: 0.02rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #DB0011;
        font-size: 0.32rem;
        color: #ffffff;
        &:active{
              background: #A7140A;
          }
    }
}
</style>

<template>
  <div class="container">
    <div class="wrap">
        <div class="title">Questionnaire</div>
        <div class="step">
            <div class="stepNum">{{step + 1}}/5</div>
            <div class="progress">
                <div class="progressValue" :style="{width:progress+'%'}"></div>
            </div>
            <div class="questionBox" v-for="(item,index) in list" :key="index" v-if="step == index">
                <div class="questionTitle">{{item.title}}</div>
                <div class="questionOption">
                    <div class="option"  v-for="(vo,k) in item.options" :key="k" @click="answer(index,k)" :class="{active:vo.checked == 1}" role="radio" :aria-checked='vo.checkedStatus'>
                        {{vo.text}}
                        <input type="radio" style="display:none;" :aria-checked="vo.checkedStatus" :value="vo.text">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btns">
        <button class="btn btn01" @click="back">Back</button>
        <button class="btn btn02 btnActive" @click="next" v-if="status">Continue</button>
        <button class="btn none" v-else>Continue</button>
    </div>
  </div>
</template>

<script>
export default {
  components:{
      
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          progress:20,
          step:0,
          list:[],
          status:false,
        }
    },
    mounted(){
        var that = this;
        // var query = JSON.parse(that.$utils.getCookie('regData'));
        // if(!query){
        //     that.$router.push({path:'/sign_up'})
        //     return false;
        // }
        that.getList();
        utag_fun(that.$route.name,1)

    },
    methods:{
        next:function(){
            var that = this;
            // for(var i in that.list[that.step].options){
            //     if(that.list[that.step].options[i].checked == 1){
            //         that.status = true;
            //     }
            // }
            // if(!that.status){
            //     return false;
            // }
            
            if(that.step < 4){
                that.step ++;
                that.progress = 20 * (that.step + 1);
                that.status =false;
            }else{
                //var questionData = that.$utils.JsonToString(that.list);
                var questionData = [];
                for(var i in that.list){
                    var options = [];
                    for(var j in that.list[i].options){
                        options.push({checked:that.list[i].options[j].checked,id:that.list[i].options[j].id,checkedStatus:that.list[i].options[j].checkedStatus})
                    }
                    questionData.push({id:that.list[i].id,options:options})
                }
                for(var i in questionData){
                    questionData[i] = JSON.stringify(questionData[i]);
                }
                console.log(questionData)
                that.$utils.setCookie('questionData',questionData.join("___"))
                that.$router.push({path:'/sign_up/terms'})
            }
            if(that.step == 1){
                utag_fun(that.$route.name,2)
            }else if(that.step == 2){
               utag_fun(that.$route.name,3)
            }else if(that.step == 3){
               utag_fun(that.$route.name,4)
            }else if(that.step == 4){
                utag_fun(that.$route.name,5)
            }
        },
        answer:function(index,k){
            var that = this;
            that.status = true;
            for(var i in that.list[index].options){
                that.list[index].options[i].checked = 0;
                that.list[index].options[i].checkedStatus = 'false';
            }
            if(that.step < 4){
                that.list[index].options[k].checked = 1;
                that.list[index].options[k].checkedStatus = 'true';
            }else{
                that.list[index].options[k].checked = 1;
                that.list[index].options[k].checkedStatus = 'true';
                that.progress = 20 * (that.step + 1);
            }
            
        },
        back:function(){
            var that = this;
            if(that.step > 0){
                that.step--;
                that.progress = 20 * (that.step + 1);
            }else if(that.step == 0){
                that.$router.go(-1);
            }
        },
        getList:function(){
            var that = this;
            that.$http.get(that.$api.getQuestionList.url, {}).then(res => {
                if(res.code == 1){
                    for(var i in res.data.lists){
                        for(var j in res.data.lists[i].options){
                            res.data.lists[i].options[j].checked = 0;
                            res.data.lists[i].options[j].checkedStatus = 'false';
                        }
                    }
                    that.list = res.data.lists;
                }else{
                    that.$toast(res.errmsg);
                }
            })
        }
    },
}
</script>

<style lang='scss' scoped>
.wrap{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 1.88rem;
    right: 0;
    padding: 0.8rem 0.4rem 0;
    box-sizing: border-box;
    overflow-y: auto;
    .title{
        font-size: 0.46rem;
        line-height: 0.6rem;
    }
    .step{
        width: 100%;
        margin-top: 0.4rem;
        .stepNum{
            width: 100%;
            height: 0.41rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 0.32rem;
        }
        .progress{
            width: 100%;
            height: 0.24rem;
            border-radius: 0.12rem;
            margin-top: 0.15rem;
            background: rgb(216,216,216);
            overflow: hidden;
            position: relative;
            .progressValue{
                height: 0.24rem;
                background: rgb(219,0,17);
                border-radius: 0.12rem;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    .questionBox{
        width: 100%;
        margin-top: 0.64rem;
        .questionTitle{
            width: 100%;
            font-size: 0.46rem;
            color: #282014;
            line-height: 0.6rem;
        }
        .questionOption{
            width: 100%;
            margin-top: 0.64rem;
            .option{
                width: 100%;
                padding: 0.3rem;
                box-sizing: border-box;
                border: 0.02rem solid #282014;
                border-radius: 0.02rem;
                font-size: 0.32rem;
                color: #282014;
                line-height: 0.41rem;
                margin-bottom: 0.32rem;
                &.active{
                    background: #4D6474;
                    color: #ffffff;
                    border: 0.02rem solid #4D6474;
                }
            }
        }
    }
}
.btns{
    width: 100%;
    height: 1.88rem;
    padding: 0.4rem;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.02rem;
    .btn{
        width: 3.27rem;
        height: 1.18rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        &.btn01{
            border:1px solid #000000;
            color: #000000;
            box-sizing: border-box;
            background: #ffffff;
        }
        &.btn02{
            background: #DB0011;
            color: #ffffff;
        }
        &.none{
            background: #D7D8D6;
            color: #ffffff;
        }
        &.btnActive{
            &:active{
              background: #A7140A;
          }
        }
    }
    .errorTips{
        width: 100%;
        
    }
}
</style>

<template>
  <div class="container">
    <div class="wrap">
        <logo :position='true' />
        <div class="view">
            <div class="title">Badminton Hub</div>
            <div class="fromBox">
                <div class="fromItem">
                    <div class="fromTitle">Username</div>
                    <div class="fromItemInput" :class="{warning:warning}">
                        <img src="@/assets/img/logon/username.png" alt="" class="usernameIcon">
                        <input type="text" name="account" id="account" value="" placeholder="Enter your username" v-model="account" :aria-invalid='accountStatus' aria-describedby='warningMsg'>
                    </div>
                </div>
                <div class="fromItem">
                    <div class="fromTitle">Password</div>
                    <div class="fromItemInput" :class="{warning:warning}">
                        <img src="@/assets/img/logon/password.png" alt="" class="PasswordIcon">
                        <input type="password" name="password" id="password" value="" v-model="password" placeholder="Enter your password" :aria-invalid='passwordStatus' aria-describedby='warningMsg'>
                    </div>
                </div>
                <div class="warning" v-show="warning">
                    <img src="@/assets/img/logon/warning.png" alt="" class="warningIcon">
                    <span :aria-live="alive" id="warningMsg">{{warningMsg}}</span>
                </div>
                <div class="remember">
                    <div class="rememberLeft">
                        <input type="checkbox" name="remember" id="remember" value="" checked>
                        <span>Remember me</span>
                    </div>
                    <div class="rememberRight">
                        <span @click="forgot">Forgotten username/password</span>
                        <img src="@/assets/img/logon/info.png" alt="" class="infoIcon">
                    </div>
                </div>
                <div class="tips" >
                    DISCLAIMER: Restricted or Highly Restricted information, including price or commercially sensitive information, should not be displayed on this website. All information must comply and be handled in accordance with published Information Security Risk policies.
                </div>
            </div>
            <div class="btnBox">
                <button class="btn" v-if="btnStatus == 0" @click="logon">Log on</button>
                <button class="btn" v-if="btnStatus == 1" :aria-live="wlive" :aria-atomic="atomic" aria-label="page is loading">
                    <img src="@/assets/img/logon/loading.gif" alt="" class="loadingIcon">
                </button>
                <button class="btn success" v-if="btnStatus == 2">
                    <img src="@/assets/img/logon/success.png" alt="" class="successIcon">
                </button>
                <button class="reg" @click="reg">
                    <span>Register</span>
                    <img src="@/assets/img/logon/arrow.png" alt="" class="regIcon">
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import logo from '@/components/logo.vue'
export default {
  components:{
      logo
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          url:this.$route.query.from?this.$route.query.from:'',
          account:'',
          password:'',
          warning:false,
          warningMsg:'',
          alive:'off',
          btnStatus:0,
          wlive:'off',
          atomic:'false',
          accountStatus:'false',
          passwordStatus:'false',
        }
    },
    mounted(){
        var that = this;
        that.check();
        utag_fun(that.$route.name);
    },
    methods:{
        check:function(){
            var that = this;
            that.$http.get(that.$api.getUserInfo.url, {}).then(res => {})
        },
        logon:function(){
            var that = this;
            // if(!that.account || !that.password){
            //     that.warning = true;
            //     return false;
            // }
            that.btnStatus = 1;
            that.wlive = 'polite';
            that.atomic = 'true';
            that.$http.post(that.$api.login.url, {account:that.account,password:that.password}).then(res => {
                if(res.code == 1){
                    that.$utils.setCookie('need_utype',JSON.stringify(res.data.userinfo),Number(res.data.userinfo.expires_in)/3600,true);
                    //that.$utils.setCookie('token',res.data.userinfo.token,Number(res.data.userinfo.expires_in)/3600,true);
                    //axios.defaults.headers.common['token'] =  res.data.userinfo.token?res.data.userinfo.token:'';
                    setTimeout(function(){
                        that.btnStatus = 2;
                    },2000)
                    if(res.data.userinfo.need_modify_pwd == 1){
                            that.warningMsg = 'Your password has not been changed in more than 30 days. For security reasons, please reset your password immediately.';
                            that.warning = true;
                            that.alive = 'polite';
                        }
                    if(that.url){
                        window.location.href = that.url;
                        return false;
                    }
                    setTimeout(function(){
                        if(res.data.userinfo.need_wjdc == 1){
                            that.$router.push({path:'/sign_up/question1'})
                        }else{
                            if(res.data.userinfo.need_modify_pwd == 1){
                                that.$router.push({path:'/my/change'})
                            }else{
                                if(res.data.userinfo.utype == 1){
                                    that.$router.push({path:'/home'})
                                }else{
                                    that.$router.push({path:'/my/family'})
                                }
                            }
                        }
                    },3000)
                    
                }else{
                    that.btnStatus = 0;
                    that.warningMsg = res.msg;
                    that.warning = true;
                    that.alive = 'polite';
                    if(res.data.efield == 'password'){
                        $('#password').focus();
                        that.passwordStatus = 'true';
                        that.accountStatus = 'false';
                    }
                    if(res.data.efield == 'account'){
                        $('#account').focus();
                        that.accountStatus = 'true';
                        that.passwordStatus = 'false';
                    }
                }
            })
        },
        reg:function(){
            var that = this;
            that.$router.push({path:'/sign_up/terms'})
        },
        forgot:function(){
            var that = this;
            that.$router.push({path:'/forgot/password'})
        },
    },
}
</script>

<style lang='scss' scoped>
.wrap{
    width: 100%;
    padding-top: 2.88rem;
    box-sizing: border-box;
    .view{
        width: 100%;
        padding: 0 0.4rem;
        box-sizing: border-box;
        .title{
            width: 100%;
            font-size: 0.4rem;
            line-height: 0.8rem;
            height: 0.8rem;
            color: #000000;
        }
        .fromBox{
            width: 100%;
            margin-top: 0.8rem;
            .fromItem{
                width: 100%;
                margin-bottom: 0.25rem;
                &:first-child{
                    margin-bottom: 0.3rem;
                }
                .fromTitle{
                    width: 100%;
                    height: 0.41rem;
                    line-height: 0.41rem;
                    font-size: 0.32rem;
                    color: #000000;
                }
                .fromItemInput{
                    width: 100%;
                    height: 0.4rem;
                    padding: 0.24rem 0;
                    border-bottom: 1px solid #000000;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: initial;
                    &.warning{
                        border-bottom: 1px solid #A8000B;
                    }
                    .usernameIcon{
                        display: block;
                        width: 0.41rem;
                        height: 0.4rem;
                    }
                    .PasswordIcon{
                        display: block;
                        width: 0.36rem;
                        height: 0.4rem;
                    }
                    >input{
                        width: 6rem;
                        height: 0.4rem;
                        line-height: 0.4rem;
                        font-size: 0.32rem;
                        color: #767676;
                        &::placeholder{
                            color: #767676;
                        }
                    }
                }
            }
            .warning{
                width: 100%;
                height: 0.36rem;
                display: flex;
                align-items: center;
                .warningIcon{
                    display: block;
                    width: 0.4rem;
                    height: 0.36rem;
                }
                >span{
                    margin-left: 0.16rem;
                    font-size: 0.19rem;
                    color: #000000;

                }
            }
            .remember{
                width: 100%;
                height: 0.28rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 0.3rem;
                .rememberLeft{
                    height: 0.28rem;
                    display: flex;
                    align-items: center;
                    >span{
                        font-size: 0.19rem;
                        margin-left: 0.12rem;
                    }
                }
                .rememberRight{
                    height: 0.28rem;
                    display: flex;
                    align-items: center;
                    >span{
                        font-size: 0.19rem;
                        margin-right: 0.12rem;
                    }
                    .infoIcon{
                        display: block;
                        width: 0.21rem;
                        height: 0.2rem;
                    }
                }
            }
            .tips{
                width: 100%;
                margin-top: 0.24rem;
                font-size: 0.19rem;
                color: #767676;
                line-height: 0.27rem;
                word-wrap: normal;word-break: keep-all;
            }
        }
        .btnBox{
            width: 100%;
            margin-top: 2.13rem;
            padding-bottom: 1rem;
            .btn{
                width: 100%;
                height: 1.18rem;
                border-radius: 0.02rem;
                background: #DB0011;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.32rem;
                color: #ffffff;
                user-select:none;
                &.none{
                    background: #D7D8D6;
                }
                &:active{
                    background: #A7140A;
                }
                &.success{
                    background: #01837E;
                }
                .successIcon{
                    display: block;
                    width: 0.48rem;
                    height: 0.36rem;
                }
                .loadingIcon{
                    display: block;
                    width: 0.48rem;
                    height: 0.48rem;
                }
            }
            .reg{
                width: 100%;
                height: 0.29rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.32rem;
                >span{
                    font-size: 0.23rem;
                    color: #000000;
                }
                .regIcon{
                    display: block;
                    width: 0.25rem;
                    height: 0.29rem;
                    margin-left: 0.12rem;
                }
            }
        }
    }
    
}
</style>

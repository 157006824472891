<template>
  <div class="container">
    <div class="wrap">
        <logo/> 
        <div class="view">
            <h1 class="title"> </h1>
            <h1 class="content">You will now access this site <br/>in mobile view.</h1>
        </div>
        <div class="btns">
          <button class="btn btnRed" @click="bind">Continue</button>
        </div>
    </div>
    <div class="popMask" v-show="popStatus" >
      <div class="popBox" aria-live='off' tabindex='1' role="dialog" :aria-modal='popStatus'>
        <button class="popClose"><img src="@/assets/img/utils/close.png" alt="close" @click="closePop" tabindex='2'></button>
        <div>
          <h1 class="title" tabindex='3'>{{detail.title}}</h1>
          <div class="content">
            <div class="progress" v-show="progressStatus">
                  <div class="progressValue" :style="{height:progress+'%',top:progressTop + '%'}"></div>
              </div>
            <vuescroll @handle-scroll="handleScroll" ref="vs">
              <p class="contentBox" v-html="detail.content" tabindex='4'>
              </p>
            </vuescroll>
          </div>
          <div class="btnSmallBox">
            <button class="btnSmall btnCookie" @click="closePop" tabindex='5'>Accept all<br/>cookies</button>
            <button class="btnSmall btnCookie" @click="cookieClick" tabindex='6'>Decline optional<br/>cookies</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/components/logo.vue'
import vuescroll from 'vuescroll';
export default {
    components:{
      logo,
      vuescroll
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          popStatus:false,
          progressStatus:false,
          progress:0,
          progressTop:0,
          detail:{},
          setCookie:1
        }
    },
    mounted(){
        var that = this;
        if(that.$utils.getCookie('cookieStatus')){
          that.popStatus = false;
          $('.container').removeAttr('tabindex');
          $('.container').removeAttr('aria-hidden');
          $('.btns button').attr({'tabindex':1})
        }else{
          that.popStatus = true;
          
          $('.container').attr({'tabindex':-1,'aria-hidden':true});
         that.$nextTick(function(){
              $('.popBox').focus();
              $('.popBox').attr({'aria-live':'polite'});
              $('.btns button').attr({'tabindex':-1})
          })
        }
        that.$nextTick(()=>{
          if(that.popStatus){
            that.getPage();
          }
          
        });
        utag_fun(that.$route.name);
    },
    methods:{
      cookieClick(){
        var that = this;
        that.$utils.setCookie('cookieSetting','close','Infinity');
        that.closePop();
      },
      cookieSet:function(){
        var that = this;
        that.popStep = 1;
        setTimeout(function(){
          that.getScroll(2);
        },500)
        
      },
      getPage:function(){
          var that = this;
          that.$http.get(that.$api.fixedPage.url, {ctype:'cookie'}).then(res => {
              if(res.code == 1){
                  that.detail = res.data.detail;
                  that.$nextTick(function () {
                        that.getScroll(1);
                    })
              }else{
                  that.$toast(res.msg);
              }
          })
      },
      closePop:function(){
        var that = this;
        that.$utils.setCookie('cookieStatus',true,'Infinity');
        that.popStatus = false;
        $('.btnCookie').blur();
        $('.btnCookie').removeAttr('tabindex')
        $('.btns button').attr({'tabindex':1})
        $('.container').removeAttr('tabindex');
        $('.container').removeAttr('aria-hidden');
      },
      getScroll:function(type){
        var that = this;
        if(type == 1){
          that.progress = that.$refs["vs"].getCurrentviewDom()[0].offsetHeight / that.$refs["vs"].getCurrentviewDom()[0].scrollHeight * 100;
        }else{
          that.progress = that.$refs["vs2"].getCurrentviewDom()[0].offsetHeight / that.$refs["vs2"].getCurrentviewDom()[0].scrollHeight * 100;
        }
        
        if(that.progress >= 99){
            that.progressStatus = false;
        }else{
            that.progressStatus = true;
        }
      },
      handleScroll:function(v,h,n){
        var that = this;
        that.progress = v.barSize * 100;
        that.progressTop = v.process * 100 - v.barSize * 100 *  v.process;
      },
      bind:function(){
        var that = this;
        that.$router.push({path:'/logon/choice'})

      }
    },
}
</script>
<style scoped>
.content >>> ul li{
    list-style: disc;
}
.content >>> ul{
    padding: 0 0 0 0.34rem;
}
.content >>> *{
    font-size: 0.28rem !important;
}
</style>
<style lang='scss' scoped>
.wrap{
    width: 100%;
    padding-top: 2.88rem;
    box-sizing: border-box;
    .view{
        width: 100%;
        padding: 0 0.4rem;
        box-sizing: border-box;
        .title{
          width: 100%;
          height: 0.8rem;
          font-size: 0.66rem;
          line-height: 0.8rem;
          color: #000000;
        }
        .content{
          width: 100%;
          font-size: 0.46rem;
          color: #000000;
          line-height: 0.8rem;
          word-break:break-all;
          
        }
    }
    .btns{
      width: 100%;
      padding: 0.4rem;
      box-sizing: border-box;
      position: fixed;
      bottom: 0;
      left: 0;
      .btn{
        width: 100%;
        height: 1.18rem;
        border-radius: 0.02rem;
        font-size: 0.32rem;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &.btnRed{
          background: #DB0011;
          color: #ffffff;
          &:active{
              background: #A7140A;
          }
        }
        &.btnBlack{
          border: 0.02rem solid #000000;
          color: #000000;
          margin-top: 0.32rem;
        }
        
      }
    }
}
.popMask{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  .popBox{
    width: 6.86rem;
    
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 0.32rem;
    box-sizing: border-box;
    .popClose{
      width: 0.2rem;
      height: 0.2rem;
      position: absolute;
      top: 0.24rem;
      right: 0.24rem;
      >img{
        width: 100%;
      }
    }
    .title{
      width: 100%;
      line-height: 0.65rem;
      font-size: 0.46rem;
      color: #333333;
      font-weight: 500;
    }
    .content{
      width: 100%;
      height: 6.3rem;
      margin-top: 0.2rem;
      text-align: left;
      font-size: 0.28rem;
      line-height: 0.38rem;
      box-sizing: border-box;
      position: relative;
      &.content_set{
        margin-top: 0.5rem;
      }
      .contentBox{
        width: 100%;
        padding-left: 0.4rem;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        text-align: left;
        font-size: 11pt;
        line-height: 0.48rem;
      }
      .setTitle{
        line-height: 0.4rem;
      }
      .setText{
        line-height: 0.4rem;
      }
      .set_item{
        display: flex;
        align-items: center;
        height: 0.3rem;
        margin: 0.15rem 0;
        >input{
          display: block;
          width: 0.3rem;
          height: 0.3rem;
        }
        >label{
          display: block;
          margin-left: 0.1rem;
          line-height: 0.3rem;
          margin-top: 0.05rem;
        }
      }
      &::-webkit-scrollbar{
              width: 0.07rem;
      }
      &::-webkit-scrollbar-track {
          background-color: rgb(216,216,216);
      }
      &::-webkit-scrollbar-thumb {
          background-color:  rgb(219,0,17); 
      }
    }
    .btnSmallBox{
      width: 100%;
      margin-top: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btnSmall{
        width: 3.02rem;
        padding: 0.3rem 0;
        border: 1px solid #000000;
        font-size: 0.28rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &.red{
          background: #db0011;
          border-color: #db0011;
          color: #ffffff;
        }
      }
    }
    .btn{
      width: 100%;
      height: 0.9rem;
      margin-top: 0.3rem;
      border: 1px solid #db0011;
      font-size: 0.32rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #db0011;
      color: #ffffff;
    }
  }
}
</style>

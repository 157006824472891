<template>
  <div class="container">
    <div class="wrap">
        <logo/>
        <div class="view">
            <div class="content">
                <img src="@/assets/img/sign/success.png" alt="success" class="successIcon">
                <span>Please check your HSBC mailbox to activate<br>your account.</span>
            </div>
        </div>
    </div>
    <div class="btns">
        <button class="btn" @click="done">Done</button>
    </div>
  </div>
</template>

<script>
import logo from '@/components/logo.vue'
export default {
  components:{
      logo
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
         
        }
    },
    mounted(){
        var that = this;
        utag_fun(that.$route.name)

    },
    methods:{
        done:function(){
            var that = this;
            that.$router.push({path:'/logon/on'})
        }
    },
}
</script>

<style lang='scss' scoped>
.wrap{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    .view{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .content{
            width: 100%;
            padding: 0 0.4rem;
            box-sizing: border-box;
            .successIcon{
                display: block;
                width: 1.2rem;
                height: 1.2rem;
                margin: 0 auto;
            }
            >span{
                display: block;
                width: 100%;
                font-size: 0.32rem;
                line-height: 0.48rem;
                margin-top: 0.8rem;
                text-align: center;
            }
        }
    }
}
.btns{
    width: 100%;
    padding: 0.4rem;
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    .btn{
        width: 100%;
        height: 1.18rem;
        border-radius: 0.02rem;
        background: #DB0011;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        color: #ffffff;
        &:active{
              background: #A7140A;
          }
    }
}
</style>

<template>
  <div class="container">
    <top :backShow='true'/>
    <div class="homeWrap">
		<div class="banner" :class="{videoStyle:detail.media_type == 'video'}" ref="banner">
            <img :src="detail.head_image" alt="" width="100%" v-if="detail.media_type == 'article'">
            <video  class="videoPlay" controls v-if="detail.media_type == 'video'" height="200px">
                <source :src="detail.video" type="video/mp4">
            </video>
        </div>
        <div class="like" ref="like">
            <i v-if="detail.is_like == 0" id="like_label">Like</i>
            <i v-if="detail.is_like == 1"  id="like_label">Unlike</i>
            <button aria-describedby='like_label'>
            <img src="@/assets/img/home/like.png" alt="like" class="likeIcon" v-if="detail.is_like == 1"  @click="unlike(detail.id)">
            <img src="@/assets/img/utils/star_none.png" alt="like" class="likeIcon" v-if="detail.is_like == 0"  @click="like(detail.id)">
            </button>
            <span>{{detail.like_num}}</span>
        </div>
        <div class="detail" :style="{top:detailTop}" >
            <h1 class="newsTitle" ref="title">{{detail.title}}</h1>
            <div class="newsContent" :style="{top:height}">
                <div class="progress" v-show="progressStatus">
                    <div class="progressValue" :style="{height:progress+'%',top:progressTop + '%'}"></div>
                </div>
                <vuescroll @handle-scroll="handleScroll" ref="vs">
                    <p class="newsContentView" v-html="detail.content"></p>
                </vuescroll>
            </div>
        </div>
    </div>
    <tabbar/>
  </div>
</template>

<script>
import tabbar from '@/components/tabbar.vue'
import top from '@/components/top.vue'
import vuescroll from 'vuescroll';
export default {
    components:{
      tabbar,
      top,
      vuescroll
    },
    head(){
        return {
            title:'',
        }
    },
    data(){
        return {
          height:'0.84rem',
          detailTop:'5.76rem',
          progress:20,
          id:this.$route.query.id,
          detail:{},
          progressStatus:false,
          progress:0,
          progressTop:0,
          flag:true,
        }
    },
    mounted(){
        var that = this;
        that.$toast.loading({
            message: 'Loading...',
            forbidClick: true,
            onClose:function(){
                
                
            }
        });
        that.getNews();
        setTimeout(function(){
                    utag_fun(that.$route.name,that.detail.title)
                },1000)
    },
    methods:{
        like:function(id){
            var that = this;
            if(that.flag){
                that.flag = false;
                that.$http.post(that.$api.articleLike.url, {id:id}).then(res => {
                    if(res.code == 1){
                        that.detail.is_like = 1;
                        that.detail.like_num++;
                        that.flag = true;
                        
                    }else{
                        that.$toast(res.msg);
                        that.flag = true;
                    }
                })
            }
            
        },
        unlike:function(id){
            var that = this;
            if(that.flag){
                that.flag = false;
                that.$http.post(that.$api.articleUnLike.url, {id:id}).then(res => {
                    if(res.code == 1){
                        that.detail.is_like = 0;
                        that.detail.like_num--;
                        that.flag = true;
                    }else{
                        that.$toast(res.msg);
                        that.flag = true;
                    }
                })
            }
            
        },
        getNews:function(){
            var that = this;
            that.$http.get(that.$api.articleDetail.url, {id:that.id}).then(res => {
                if(res.code == 1){
                    that.detail = res.data.detail;
                    
                    setTimeout(function(){
                        that.$nextTick(function(){
                            that.getHeight();
                        })
                    },1000)
                    
                }else{
                    that.$toast(res.msg);
                }
            })
        },
        getHeight:function(){
            var that = this;
            let detail= this.$refs.banner.offsetHeight + this.$refs.like.offsetHeight;
            that.detailTop = detail + 'px';
            let height= this.$refs.title.offsetHeight;
            that.height = height +'px';
            
            that.getScroll();
        },
        getScroll:function(){
            var that = this;
            that.progress = that.$refs["vs"].getCurrentviewDom()[0].offsetHeight / that.$refs["vs"].getCurrentviewDom()[0].scrollHeight * 100;
            if(that.progress >= 99){
                that.progressStatus = false;
            }else{
                that.progressStatus = true;
            }
            that.$toast.clear()
            
        },
        handleScroll:function(v,h,n){
            var that = this;
            that.progress = v.barSize * 100;
            that.progressTop = v.process * 100 - v.barSize * 100 *  v.process;
        },
    },
}
</script>
<style scoped>
.newsContentView >>> ul li{
    list-style: disc;
}
.newsContentView >>> ul{
    padding: 0 0 0 0.34rem;
}
.newsContentView >>> *{
    font-size: 0.28rem !important;
}
</style>
<style lang='scss' scoped>
.homeWrap{
    overflow: hidden;
    .banner{
        width: 100%;
        // height: 4.82rem;
        overflow: hidden;
        &.videoStyle{
            background: #000000;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .videoPlay{
            max-width: 100%;
            max-height: 100%;
        }
    }
    .like{
        width: 100%;
        height: 0.69rem;
        padding: 0 0.4rem;
        padding-top: 0.32rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        >i{
            font-size: 0.19rem;
            color: #9B9B9B;
            margin-right: 0.07rem;
            white-space: nowrap;
        }
        .likeIcon{
            display: block;
            width: 0.44rem;
            height: 0.37rem;
        }
        >span{
            font-size: 0.19rem;
            color: #9B9B9B;
            margin-left: 0.07rem;
        }
    }
    .detail{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 0 0.23rem 0.4rem 0.21rem;
        box-sizing: border-box;
        .newsTitle{
            width: 100%;
            font-size: 0.46rem;
            line-height: 0.6rem;
            padding-bottom: 0.24rem;
        }
        .newsContent{
            position: absolute;
            left: 0.21rem;
            right: 0.23rem;
            bottom: 0.4rem;
            .progress{
                width: 0.07rem;
                height: 100%;
                background:rgb(216,216,216);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 5;
                .progressValue{
                    width: 100%;
                    background: rgb(219,0,17);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            .newsContentView{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                font-size: 0.28rem !important;
                color: #767676;
                line-height: 0.38rem;
                padding-left: 0.29rem;
                box-sizing: border-box;
                text-align: left;
                &::-webkit-scrollbar{
                    width: 0.07rem;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgb(216,216,216);
                }
                &::-webkit-scrollbar-thumb {
                    background-color:  rgb(219,0,17); 
                }
            }
        }
    }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LogonWelcome from '../views/logon/welcome.vue'
import LogonChoice from '../views/logon/choice.vue'
import LogonOn from '../views/logon/on.vue'
import Username from '../views/forgot/username.vue'
import Password from '../views/forgot/password.vue'
import Success from '../views/forgot/success.vue'
import SignUp from '../views/sign_up/index.vue'
import Question from '../views/sign_up/question.vue'
import Question1 from '../views/sign_up/question1.vue'
import Question2 from '../views/sign_up/question2.vue'
import Question3 from '../views/sign_up/question3.vue'
import Question4 from '../views/sign_up/question4.vue'
import Question5 from '../views/sign_up/question5.vue'
import SignSuccess from '../views/sign_up/success.vue'
import Start from '../views/sign_up/start.vue'
import Terms from '../views/sign_up/terms.vue'
import Index from '../views/home/index.vue'
import Articles from '../views/home/articles.vue'
import News from '../views/home/news.vue'
import Play from '../views/play/index.vue'
import Game from '../views/play/game.vue'
import Share from '../views/play/share.vue'
import ShareView from '../views/play/share_view.vue'
import Webcasts from '../views/webcasts/index.vue'
import Contact from '../views/webcasts/contact.vue'
import Wait from '../views/webcasts/wait.vue'
import My from '../views/my/index.vue'
import Change from '../views/my/change.vue'
import ChangeSuccess from '../views/my/wait.vue'
import Points from '../views/my/points.vue'
import MyQuestion from '../views/my/question.vue'
import Family from '../views/my/family.vue'
import MyTerms from '../views/my/terms.vue'
import MyContact from '../views/my/contact.vue'
import Policy from '../views/sign_up/policy.vue'
import SetPassword from '../views/my/setpassword.vue'
import Index1 from '../views/index1.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {path: '/',name: 'Home',component: Home,meta:{title:'HSBC Badminton Hub'}},
  {path:'/logon/welcome',name:'LogonWelcome',component:LogonWelcome,meta:{title:'HSBC Badminton Hub'}},
  {path:'/logon/choice',name:'LogonChoice',component:LogonChoice,meta:{title:'HSBC Badminton Hub'}},
  {path:'/logon/on',name:'LogonOn',component:LogonOn,meta:{title:'HSBC Badminton Hub'}},
  {path:'/forgot/username',name:'Username',component:Username,meta:{title:'HSBC Badminton Hub'}},
  {path:'/forgot/password',name:'Password',component:Password,meta:{title:'HSBC Badminton Hub'}},
  {path:'/forgot/success',name:'Success',component:Success,meta:{title:'HSBC Badminton Hub'}},
  {path:'/sign_up',name:'SignUp',component:SignUp,meta:{title:'HSBC Badminton Hub'}},
  {path:'/sign_up/question',name:'Question',component:Question,meta:{title:'HSBC Badminton Hub'}},
  {path:'/sign_up/question1',name:'Question1',component:Question1,meta:{title:'HSBC Badminton Hub'}},
  {path:'/sign_up/question2',name:'Question2',component:Question2,meta:{title:'HSBC Badminton Hub'}},
  {path:'/sign_up/question3',name:'Question3',component:Question3,meta:{title:'HSBC Badminton Hub'}},
  {path:'/sign_up/question4',name:'Question4',component:Question4,meta:{title:'HSBC Badminton Hub'}},
  {path:'/sign_up/question5',name:'Question5',component:Question5,meta:{title:'HSBC Badminton Hub'}},
  {path:'/sign_up/success',name:'SignSuccess',component:SignSuccess,meta:{title:'HSBC Badminton Hub'}},
  {path:'/sign_up/start',name:'Start',component:Start,meta:{title:'HSBC Badminton Hub'}},
  {path:'/sign_up/terms',name:'Terms',component:Terms,meta:{title:'HSBC Badminton Hub'}},
  {path:'/home',name:'Index',component:Index,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/home/articles',name:'Articles',component:Articles,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/home/news',name:'News',component:News,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/play',name:'Play',component:Play,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/play/game',name:'Game',component:Game,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/play/share',name:'Share',component:Share,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/play/share_view',name:'ShareView',component:ShareView,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/webcasts',name:'Webcasts',component:Webcasts,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/webcasts/contact',name:'Contact',component:Contact,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/webcasts/wait',name:'Wait',component:Wait,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/my',name:'My',component:My,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/my/change',name:'Change',component:Change,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/my/wait',name:'ChangeSuccess',component:ChangeSuccess,meta:{title:'HSBC Badminton Hub'}},
  {path:'/my/points',name:'Points',component:Points,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/my/question',name:'MyQuestion',component:MyQuestion,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/my/family',name:'Family',component:Family,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/my/terms',name:'MyTerms',component:MyTerms,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/my/contact',name:'MyContact',component:MyContact,meta:{title:'HSBC Badminton Hub',requireAuth:true}},
  {path:'/sign_up/policy',name:'Policy',component:Policy,meta:{title:'HSBC Badminton Hub'}},
  {path:'/my/setpassword',name:'SetPassword',component:SetPassword,meta:{title:'HSBC Badminton Hub'}},
  {path:'/index1',name:'Index1',component:Index1,meta:{title:'HSBC Badminton Hub'}},
]

const router = new VueRouter({
  // mode:'history',
  // base: '/dist',
  routes
})


export default router

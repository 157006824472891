<template>
  <div class="container">
    <top :backShow='true'/>
    <div class="homeWrap">
		<div class="avatar">
            <div class="avatarBox">
                <img :src="userinfo.avatar" alt="" width="100%">
            </div>
            <div class="avatarEdit">
                <input type="file" accept="image/*" @change="inputFileChange" class="upbtn">
                <img src="@/assets/img/my/change_avatar.png" alt="change avatar" class="changeIcon">
            </div>
        </div>
        <div class="nickname">{{userinfo.nickname}}</div>
        <div class="changePassword" @click="change">Change password</div>
        <div class="list">
            <div class="item" @click="game">
                <div class="itemTitle">
                    <span>Play the game</span>
                    <img src="@/assets/img/utils/arrow.png" alt="" class="arrowIcon">
                </div>
                <div class="itemPic">
                    <!-- <div class="itemPicTitle">Please use your mobile device for optimal experience</div> -->
                    <img src="@/assets/img/test/play the game.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <tabbar/>
  </div>
</template>

<script>
import tabbar from '@/components/tabbar.vue'
import top from '@/components/top.vue'
export default {
    components:{
      tabbar,
      top
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          userinfo:{},
        }
    },
    mounted(){
        var that = this;
        that.getIndexInfo();
        utag_fun(that.$route.name)
    },
    methods:{
        inputFileChange (e){
            var that = this;
            if (e.target.files[0].type != 'image/jpeg' && e.target.files[0].type != 'image/png') {
                that.$toast('Please upload pictures in JPG or PNG format');
                return false;
            }
            let formData = new window.FormData();
            that.$toast.loading({
                message: 'Uploading...',
                forbidClick: true,
            })
            formData.append('file',e.target.files[0])
            that.$http.post(that.$api.upload.url, formData,{'content-type': 'multipart/form-data',}).then(res => {
                if(res.code == 1){
                    that.edit(res.data.url);
                }else{
                    that.$toast.clear()
                    that.$toast(res.msg);
                }
            }).catch(res=>{
                alert(res+'444')
            })
        },
        afterRead:function(file){
            var that = this;
            that.$toast.loading({
                message: 'Uploading...',
                forbidClick: true,
            })
            let formData = new window.FormData();
            formData.append('file',file.file)

            that.$http.post(that.$api.upload.url, formData,{'content-type': 'multipart/form-data',}).then(res => {
                if(res.code == 1){
                    that.edit(res.data.url);
                }else{
                    that.$toast.clear()
                    that.$toast(res.msg);
                }
            }).catch(res=>{
                alert(res+'444')
            })
        },
        beforeRead:function(file){
            var that = this;
            if (file.type != 'image/jpeg' && file.type != 'image/png') {
                that.$toast('Please upload pictures in JPG or PNG format');
                return false;
            }
            return true;
        },
        edit:function(url){
            var that = this;
            that.$http.post(that.$api.profile.url, {avatar:url}).then(res => {
                if(res.code == 1){
                    that.getIndexInfo(1);
                }else{
                    that.$toast.clear()
                    that.$toast(res.msg);
                }
            })
        },
        change:function(){
            var that = this;
            that.$router.push({path:'/my/change'})
        },
        getIndexInfo:function(){
            var that = this;
            that.$http.get(that.$api.userIndex.url, {}).then(res => {
                if(res.code == 1){
                    that.userinfo = res.data.uinfo;
                }else{
                    that.$toast(res.msg);
                }
            })
        },
        game:function(){
            var that = this;
            //that.$router.push({path:'/play/game'})
            that.$http.post(that.$api.goToGame.url, {}).then(res => {
                if(res.code == 1){
                    window.location.href = that.$utils.host() + '/game';
                }else{
                    that.$toast(res.msg);
                }
            })
             
        }
    },
}
</script>

<style lang='scss' scoped>
.homeWrap{
    width: 100%;
    padding-top: 0.4rem;
    box-sizing: border-box;
    .avatar{
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 auto;
        position: relative;
        .avatarBox{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
        }
        .avatarEdit{
            width: 0.38rem;
            height: 0.38rem;
            position: absolute;
            bottom: 0.09rem;
            right: 0.01rem;
            .changeIcon{
                display: block;
                width: 100%;
                height: 100%;
            }
            .upbtn{
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                opacity: 0;
            }
        }
    }
    .nickname{
        width: 100%;
        line-height: 0.6rem;
        font-weight: bold;
        font-size: 0.46rem;
        text-align: center;
    }
    .changePassword{
        width: 2.32rem;
        height: 0.41rem;
        border: 1px solid #BCBCBC;
        border-radius: 0.04rem;
        box-sizing: border-box;
        margin: 0.08rem auto 0;
        font-size: 0.19rem;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .list{
        width: 100%;
        padding: 0 0.24rem;
        box-sizing: border-box;
        margin-top: 1.52rem;
        .item{
            width: 100%;
            margin-bottom: 0.24rem;
            .itemTitle{
                width: 100%;
                height: 0.48rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                >span{
                    font-size: 0.32rem;

                }
                .arrowIcon{
                    display: block;
                    width: 0.18rem;
                    height: 0.21rem;
                }
            }
            .itemPic{
                width: 100%;
                height: 4.86rem;
                overflow: hidden;
                position: relative;
                margin-top: 0.16rem;
                >img{
                    width: 100%;
                }
                .itemPicTitle{
                    width: 100%;
                    height: 100%;
                    padding: 0.24rem;
                    box-sizing: border-box;
                    font-size: 0.32rem;
                    color: #ffffff;
                    line-height: 0.48rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}
</style>

<template>
  <div class="tabbarBox">
    <div class="tabbar" v-if="type == 1">
        <a class="tabbarItem" @click="menuClick" href="javascript:void(0)" :aria-expanded='menuShowStatus'>
            <img src="@/assets/img/tabbar/Menu.png" alt="Menu" class="menuIcon">
            <span>Menu</span>
        </a>
        <router-link class="tabbarItem tabbarItemLong" to="/play" style="margin-left:-0.1rem;">
            <img src="@/assets/img/tabbar/Play_and_share.png" alt="Play and share" class="playIcon">
            <span>Play and share</span>
        </router-link>
        <router-link class="tabbarItem" to="/webcasts" style="margin-left:-0.1rem;">
            <img src="@/assets/img/tabbar/Webcasts.png" alt="Webcasts" class="webIcon" >
            <span>Webcasts</span>
        </router-link>
        <router-link class="tabbarItem" to="/my">
            <img src="@/assets/img/tabbar/My_profile.png" alt="My profile" class="myIcon">
            <span>My profile</span>
        </router-link>
        <div class="menu" v-show="menuShow">
            <button class="menuClose" @click="menuClose"><img src="@/assets/img/utils/close.png" alt="close"></button>
            <div class="menuTitle">Menu</div>
            <div class="menuList">
                <router-link class="menuItem" to="/my" id="menuFirst" tabIndex='0'><span>My profile</span></router-link>
                <router-link class="menuItem" to="/home/articles"><span>Latest articles</span></router-link>
                <router-link class="menuItem" to="/play"><span>Play and share</span></router-link>
                <router-link class="menuItem" to="/my/points"><span>Points and prizes</span></router-link>
                <router-link class="menuItem" to="/webcasts"><span>Well-being webcasts</span></router-link>
                <router-link class="menuItem" to="/my/question"><span>Frequently asked questions</span></router-link>
                <router-link class="menuItem" to="/my/terms"><span>Terms and conditions</span></router-link>
                <router-link class="menuItem" to="/my/contact"><span>Contact us</span></router-link>
                <a href="javascript:void(0)" class="menuItem" @click="logOut"><span>Log out</span></a>
            </div>
        </div>
    </div>
    <div class="tabbar" v-if="type == 2">
        <div class="tabbarItem" @click="game">
            <img src="@/assets/img/tabbar/Play_and_share.png" alt="Play the game" class="playIcon">
            <span>Play</span>
        </div>
        <router-link class="tabbarItem" to="/my/contact">
            <img src="@/assets/img/tabbar/Contact_us.png" alt="contact us" class="contactIcon">
            <span>Contact us</span>
        </router-link>
        <router-link class="tabbarItem tabbarItemLong" to="/my/terms">
            <img src="@/assets/img/tabbar/Terms.png" alt="Terms and conditions" class="termsIcon">
            <span>Terms and conditions</span>
        </router-link>
        <a class="tabbarItem"  @click="logOut" href="javascript:void(0)">
            <img src="@/assets/img/tabbar/Log_out.png" alt="Log out" class="outIcon">
            <span>Log out</span>
        </a>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        
    },
    data(){
        return {
            menuShow:false,
            menuShowStatus:'false',
            type:1,

        }
    },
    components: {
        
    },
    async mounted(){
        var that = this;
        if(that.$utils.getCookie('need_utype')){
            that.type = JSON.parse(that.$utils.getCookie('need_utype')).utype;
            if(JSON.parse(that.$utils.getCookie('need_utype')).need_modify_pwd == 1){
                that.$toast('请修改密码');
                setTimeout(function(){
                    that.$router.push({path:'/my/change'})
                },2000)
            }
        }else{
            that.getUserInfo();
        }
        
    },
    methods:{
        game:function(){
            var that = this;
            that.$http.post(that.$api.goToGame.url, {}).then(res => {
                if(res.code == 1){
                    window.location.href = that.$utils.host() + '/game';
                }else{
                    that.$toast(res.msg);
                }
            })
             
        },
        getUserInfo:function(){
            var that = this;
            that.$http.post(that.$api.utype.url, {}).then(res => {
                if(res.code == 1){
                    that.type = res.data.utype;
                    if(res.data.need_modify_pwd == 1){
                        that.$toast('请修改密码');
                        setTimeout(function(){
                            that.$router.push({path:'/my/change'})
                        },2000)
                    }
                }else{
                    that.$toast(res.msg);
                }
            })
        },
        menuClick:function(){
            var that = this;
            that.menuShow = true;
            that.menuShowStatus = 'true';
            that.$nextTick(function(){
                 $('#menuFirst').focus();
            })
        },
        menuClose:function(){
            var that = this;
             that.menuShow = false;
             that.menuShowStatus = 'false';
        },
        logOut:function(){
            var that = this;
            that.$http.post(that.$api.logout.url, {}).then(res => {
                if(res.code == 1){
                    that.$utils.delCookie('need_utype');
                    that.$router.push({path:'/logon/welcome'})
                }else{
                    that.$toast(res.msg);
                }
            })
        }
    },
}
</script>
<style lang='scss'>

.tabbarBox{
    width: 100%;
    height: 0.98rem;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid #eeeeee;
    box-sizing: border-box;
    .tabbar{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.2rem;
        z-index: 5;
        background: #ffffff;
        box-sizing: border-box;
        .tabbarItem{
            display: block;
            width: 1.5rem;
            height: 100%;
            padding-top: 0.1rem;
            box-sizing: border-box;
            &.tabbarItemLong{
                width: 2.5rem;
            }
            .menuIcon{
                display: block;
                width: 0.4rem;
                height: 0.41rem;
                margin: 0 auto;
            }
            .playIcon{
                display: block;
                width: 0.42rem;
                height: 0.41rem;
                margin: 0 auto;
            }
            .webIcon{
                display: block;
                width: 0.41rem;
                height: 0.41rem;
                margin: 0 auto;
            }
            .myIcon{
                display: block;
                width: 0.44rem;
                height: 0.41rem;
                margin: 0 auto;
            }
            .contactIcon{
                display: block;
                width: 0.46rem;
                height: 0.36rem;
                margin: 0 auto;
            }
            .termsIcon{
                display: block;
                width: 0.36rem;
                height: 0.4rem;
                margin: 0 auto;
            }
            .outIcon{
                display: block;
                width: 0.41rem;
                height: 0.39rem;
                margin: 0 auto;
            }
            >span{
                display: block;
                font-size: 0.19rem;
                color: #1E1F24;
                text-align: center;
                margin-top: 0.1rem;
            }
        }
        .menu{
            width: 100%;
            background: #ffffff;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 6;
            padding-left: 0.5rem;
            box-sizing: border-box;
            .menuClose{
                width: 0.24rem;
                height: 0.24rem;
                position: absolute;
                top: 0.33rem;
                right: 0.33rem;
                >img{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .menuTitle{
                width: 50%;
                font-size: 0.35rem;
                font-weight: 500;
                line-height: 0.8rem;
                margin-left: -0.26rem;
            }
            .menuList{
                width: 100%;
                margin-top: 0.1rem;
                .menuItem{
                    width: 100%;
                    height: 0.8rem;
                    display: flex;
                    align-items: center;
                    border-bottom:1px solid #979797;
                    box-sizing: border-box;
                    >span{
                        margin-left: -0.26rem;
                        font-size: 0.3rem;
                    }
                    &:last-child{
                        border-bottom: 0 none;
                    }
                }
            }
        }
    }
}
</style>
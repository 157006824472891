<template>
  <div class="container">
    <top :backShow='true' :backType='true'/>
    <div class="homeWrap">
		<h1 class="title">Play and share</h1>
        <div class="list">
            <router-link class="item" to="/play/share">
                <div class="itemTitle">
                    <h2>Share with us</h2>
                    <img src="@/assets/img/utils/arrow.png" alt="" class="arrowIcon">
                </div>
                <div class="itemPic">
                    <!-- <div class="itemPicTitle">Test your skills</div> -->
                    <img src="@/assets/img/test/share with us.jpg" alt="">
                </div>
            </router-link>
            <div class="item" @click="bind(2)">
                <div class="itemTitle">
                    <h2>Play the game</h2>
                    <img src="@/assets/img/utils/arrow.png" alt="" class="arrowIcon">
                </div>
                <div class="itemPic">
                    <!-- <div class="itemPicTitle">Virtual badminton game </div> -->
                    <img src="@/assets/img/test/play the game.png" alt="">
                </div>
            </div>
        </div>
        <audio src="@/assets/nsbc.mp3" id="music" preload></audio>
    </div>
    <tabbar/>
  </div>
</template>

<script>
import tabbar from '@/components/tabbar.vue'
import top from '@/components/top.vue'
export default {
    components:{
      tabbar,
      top
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          
        }
    },
    mounted(){
        var that = this;
        utag_fun(that.$route.name)

    },
    methods:{
        bind:function(type){
            var that = this;
            if(type == 1){
                that.$router.push({path:'/play/share'})
            }
            if(type == 2){
                that.$http.post(that.$api.goToGame.url, {}).then(res => {
                    if(res.code == 1){
                        window.location.href = that.$utils.host() + '/game';
                    }else{
                        that.$toast(res.msg);
                    }
                })
                // var audio = document.getElementById('music');
                // audio.play();
                
                // setTimeout(function(){
                //     window.location.href = '//badminton.hsbc.com/game';
                //     //that.$router.push({path:'/play/game'})
                // },2000)
                 
            }
        }
    },
}
</script>

<style lang='scss' scoped>
.homeWrap{
    width: 100%;
    padding: 0.16rem 0.24rem 0.24rem;
    box-sizing: border-box;
    .title{
        width: 100%;
        line-height: 0.6rem;
        font-size: 0.46rem;
        font-weight: 500;
    }
    .list{
        width: 100%;
        margin-top: 0.16rem;
        .item{
            display: block;
            width: 100%;
            margin-bottom: 0.24rem;
            .itemTitle{
                width: 100%;
                height: 0.48rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                >h2{
                    font-size: 0.32rem;
                }
                .arrowIcon{
                    display: block;
                    width: 0.18rem;
                    height: 0.21rem;
                }
            }
            .itemPic{
                width: 100%;
                height: 4.86rem;
                overflow: hidden;
                position: relative;
                margin-top: 0.16rem;
                >img{
                    width: 100%;
                }
                .itemPicTitle{
                    width: 100%;
                    height: 100%;
                    padding: 0.24rem;
                    box-sizing: border-box;
                    font-size: 0.32rem;
                    color: #ffffff;
                    line-height: 0.48rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}
</style>

<template>
  <div class="container">
    <div class="wrap">
        <div class="title">Questionnaire</div>
        <div class="step">
            <div class="stepNum">4/5</div>
            <div class="progress">
                <div class="progressValue" style="width:80%"></div>
            </div>
            <div class="questionBox">
                <div class="questionTitle">What area are you most interested to learn more about?</div>
                <div class="questionOption">
                    <div class="option" @click="answer(1)" :class="{active:checked == 1}" role="radio" :aria-checked='{"true":checked == 1}'>
                        Badminton related news and updates
                        <input type="radio" style="display:none;" :aria-checked='{"true":checked == 1}' value="Badminton related news and updates">
                    </div>
                    <div class="option" @click="answer(2)" :class="{active:checked == 2}" role="radio" :aria-checked='{"true":checked == 2}'>
                        Fun family activities
                        <input type="radio" style="display:none;" :aria-checked='{"true":checked == 2}' value="Fun family activities">
                    </div>
                    <div class="option" @click="answer(3)" :class="{active:checked == 3}" role="radio" :aria-checked='{"true":checked == 3}'>
                        Mental and physical health
                        <input type="radio" style="display:none;" :aria-checked='{"true":checked == 3}' value="Mental and physical health">
                    </div>
                    <div class="option" @click="answer(4)" :class="{active:checked == 4}" role="radio" :aria-checked='{"true":checked == 4}'>
                        Food and nutrition
                        <input type="radio" style="display:none;" :aria-checked='{"true":checked == 4}' value="Food and nutrition">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btns">
        <button class="btn btn01" @click="back">Back</button>
        <button class="btn btn02 btnActive" @click="next" v-if="status">Continue</button>
        <button class="btn none" v-else>Continue</button>
    </div>
  </div>
</template>

<script>
export default {
  components:{
      
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          progress:20,
          step:0,
          list:[],
          status:false,
          checked:0,
        }
    },
    mounted(){
        var that = this;
        // var query = that.$utils.getCookie('regData');
        // if(!query){
        //     that.$router.push({path:'/sign_up'})
        //     return false;
        // }
        that.getList();
        utag_fun(that.$route.name)
    },
    methods:{
        next:function(){
            var that = this;
            var index = that.checked;
            that.list[3].options[index - 1].checked = 1;
            that.list[3].options[index - 1].checkedStatus = true;
            var questionData = [];
            for(var i in that.list){
                var options = [];
                for(var j in that.list[i].options){
                    options.push({checked:that.list[i].options[j].checked,id:that.list[i].options[j].id,checkedStatus:that.list[i].options[j].checkedStatus})
                }
                questionData.push({id:that.list[i].id,options:options})
            }
            for(var i in questionData){
                questionData[i] = JSON.stringify(questionData[i]);
            }
            console.log(questionData)
            that.$utils.setCookie('questionData',questionData.join("___"))
            that.$router.push({path:'/sign_up/question5'})
        },
        answer:function(index){
            var that = this;
            that.status = true;
            that.checked = index;
            for(var i in that.list[3].options){
                that.list[3].options[i].checked = 0;
                that.list[3].options[i].checkedStatus = false;
            }
        },
        back:function(){
            var that = this;
            that.$router.go(-1);
        },
        getList:function(){
            var that = this;
            var list = that.$utils.getCookie('questionData').split('___');
            for(var i in list){
                list[i] = JSON.parse(list[i])
            }
            that.list = list;
            console.log(list)
        }
    },
}
</script>

<style lang='scss' scoped>
.wrap{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 1.88rem;
    right: 0;
    padding: 0.8rem 0.4rem 0;
    box-sizing: border-box;
    overflow-y: auto;
    .title{
        font-size: 0.46rem;
        line-height: 0.6rem;
    }
    .step{
        width: 100%;
        margin-top: 0.4rem;
        .stepNum{
            width: 100%;
            height: 0.41rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 0.32rem;
        }
        .progress{
            width: 100%;
            height: 0.24rem;
            border-radius: 0.12rem;
            margin-top: 0.15rem;
            background: rgb(216,216,216);
            overflow: hidden;
            position: relative;
            .progressValue{
                height: 0.24rem;
                background: rgb(219,0,17);
                border-radius: 0.12rem;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    .questionBox{
        width: 100%;
        margin-top: 0.64rem;
        .questionTitle{
            width: 100%;
            font-size: 0.46rem;
            color: #282014;
            line-height: 0.6rem;
        }
        .questionOption{
            width: 100%;
            margin-top: 0.64rem;
            .option{
                width: 100%;
                padding: 0.3rem;
                box-sizing: border-box;
                border: 0.02rem solid #282014;
                border-radius: 0.02rem;
                font-size: 0.32rem;
                color: #282014;
                line-height: 0.41rem;
                margin-bottom: 0.32rem;
                &.active{
                    background: #4D6474;
                    color: #ffffff;
                    border: 0.02rem solid #4D6474;
                }
            }
        }
    }
}
.btns{
    width: 100%;
    height: 1.88rem;
    padding: 0.4rem;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.02rem;
    .btn{
        width: 3.27rem;
        height: 1.18rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        &.btn01{
            border:1px solid #000000;
            color: #000000;
            box-sizing: border-box;
            background: #ffffff;
        }
        &.btn02{
            background: #DB0011;
            color: #ffffff;
        }
        &.none{
            background: #D7D8D6;
            color: #ffffff;
        }
        &.btnActive{
            &:active{
              background: #A7140A;
          }
        }
    }
    .errorTips{
        width: 100%;
        
    }
}
</style>

<template>
  <div class="container">
    <div class="wrap">
        <h1 class="title">{{detail.title}}</h1>
        <h2 class="timeBox"><span>{{detail.term_utime}}</span></h2>
        <div class="termsBox">
            <div class="progress" v-show="progressStatus">
                <div class="progressValue" :style="{height:progress+'%',top:progressTop + '%'}"></div>
            </div>
            <vuescroll @handle-scroll="handleScroll" ref="vs">
                <div class="termsContent" v-html="detail.content">
                    <!-- <div v-for="(item,index) in 10" :key="index">
                        <div class="termsTitle">1.Terms</div>
                        <div class="termsTxt">
                            This website, https://bootstrapious.com, is operatewd by Ondrej Svestka and Bootstrapious. Throughout the site, the terms “we”, “us” and “our” refer to Ondrej Svestka and Bootstrapious. We offer this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
                        </div>
                    </div> -->
                </div>
            </vuescroll>
        </div>
    </div>
    <div class="btns">
        <button class="btn btn01" @click="back">Back</button>
        <button class="btn btn02" :class="{success:btnStatus}" @click="next" :aria-disabled='btnStatusStatus' aria-haspoup='dialog'>Continue</button>
    </div>
    <div class="mask" v-show="popStatus">
        <div class="pop" aria-live='off' tabindex='1'  role="dialog" :aria-modal='popStatus'>
            <button class="popClose"><img src="@/assets/img/sign/close.png" alt="close"  @click="closePop" tabindex='2'></button>
            <div class="popTitle" tabindex='3'>Terms and conditions</div>
            <div class="popContent">
                <!-- <i v-if="!popCheck" @click="popCheckBind" tabindex='4' :aria-checked='popCheck'></i>
                <img src="@/assets/img/sign/tick box.png" alt="tick box" class="tick box" v-else @click="popCheckBind" tabindex='4' :aria-checked='popCheck'> -->
                <input type="checkbox" class="" @change="popCheckBind" :aria-checked='popCheck' tabindex='4' :checked='popCheck'>
                <span @click="popCheckBind" tabindex='5'>I have read and hereby agree to the terms and conditions.</span>
            </div>
            <button class="popBtn" :class="{active:popCheck}" @click="reg" v-if="btnStep == 0" tabindex='6' :aria-disabled='popCheckStatus'>Continue</button>
            <button class="popBtn active" v-if="btnStep == 1" :aria-live="wlive" :aria-atomic="atomic" aria-label="page is loading">
                <img src="@/assets/img/logon/loading.gif" alt="" class="loadingIcon">
            </button>
            <button class="popBtn success" v-if="btnStep == 2">
                <img src="@/assets/img/logon/success.png" alt="" class="successIcon">
            </button>
        </div>
    </div>
  </div>
</template>

<script>
import vuescroll from 'vuescroll';
export default {
    components:{
      vuescroll
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          btnStatus:false,
          btnStatusStatus:'true',
          progressStatus:false,
          progress:20,
          popStatus:false,
          popCheck:false,
          popCheckStatus:'true',
          progress:0,
          progressTop:0,
          detail:{},
          btnStep:0,
          wlive:'off',
          atomic:'false',
        }
    },
    mounted(){
        var that = this;
        // var questionData = that.$utils.getCookie('questionData');
        // questionData = questionData.split("___");
        //  for(var i in questionData){
        //      questionData[i] =  JSON.parse(questionData[i])
        //  }
        // var query = that.$utils.getCookie('regData');
        // if(!query){
        //     that.$router.push({path:'/sign_up'})
        //     return false;
        // }
        
        // if(!questionData){
        //     that.$router.push({path:'/sign_up/question1'})
        //     return false;
        // }
        that.getPage();
        utag_fun(that.$route.name)
    },
    methods:{
        getPage:function(){
            var that = this;
            that.$http.get(that.$api.fixedPage.url, {ctype:'use_item'}).then(res => {
                if(res.code == 1){
                    that.detail = res.data.detail;
                    that.$nextTick(function () {
                        that.getScroll();
                    })
                    
                }else{
                    that.$toast(res.msg);
                }
            })
        },
        reg:function(){
            var that = this;
            if(!that.popCheck){
                return false;
            }
            that.btnStep = 1;
            that.wlive = 'polite';
            that.atomic = 'true';
            setTimeout(function(){
                that.btnStep = 2;
            },2000)
            setTimeout(function(){
                that.$router.push({path:'/sign_up'})
            },3000)
            // var questionData = that.$utils.getCookie('questionData');
            // questionData = questionData.split("___");
            // for(var i in questionData){
            //     questionData[i] =  JSON.parse(questionData[i])
            // }
            //var query = JSON.parse(that.$utils.getCookie('regData'));
            // var query = {};
            // query.str = that.$utils.getCookie('regData');
            // query.alists = questionData;
            // that.$http.post(that.$api.register.url, query).then(res => {
            //     if(res.code == 1){
            //         that.$utils.setCookie('need_utype',JSON.stringify(res.data.userinfo),Number(res.data.userinfo.expires_in)/3600,true);
            //         // that.$utils.delCookie('regData');
            //         that.$utils.delCookie('questionData');
            //         setTimeout(function(){
            //             that.btnStep = 2;
            //         },2000)
            //         setTimeout(function(){
            //             that.$router.push({path:'/sign_up'})
            //         },3000)
                    
            //     }else{
            //         that.$toast(res.msg);
            //     }
            // })
        },
        getScroll:function(){
            var that = this;
            that.progress = that.$refs["vs"].getCurrentviewDom()[0].offsetHeight / that.$refs["vs"].getCurrentviewDom()[0].scrollHeight * 100;
            if(that.progress >= 99){
                that.progressStatus = false;
            }else{
                that.progressStatus = true;
            }
        },
        handleScroll:function(v,h,n){
            var that = this;
            that.progress = v.barSize * 100;
            that.progressTop = v.process * 100 - v.barSize * 100 *  v.process;
            that.btnStatus = true;
            that.btnStatusStatus = 'false';
        },
        popCheckBind:function(){
            var that = this;
            if(that.popCheck){
                that.popCheck = false;
                that.popCheckStatus = 'true';
            }else{
                that.popCheck = true;
                that.popCheckStatus = 'false';
            }
        },
        closePop:function(){
            var that = this;
            that.popStatus = false;
            that.btnStep = 0;
            $('.btns button').attr({'tabindex':1})
            $('.container').removeAttr('tabindex');
            $('.container').removeAttr('aria-hidden');
        },
        next:function(){
            var that = this;
            if(that.btnStatus){
                that.popStatus = true;
                $('.container').attr({'tabindex':-1,'aria-hidden':true});
                that.$nextTick(function(){
                    $('.pop').focus();
                    $('.pop').attr({'aria-live':'polite'});
                    $('.btns button').attr({'tabindex':-1})
                })
                
            }
        },
        back:function(){
            var that = this;
            that.$router.go(-1);
        },
    },
}
</script>
<style scoped>
.termsContent >>> ul li{
    list-style: disc;
}
.termsContent >>> ul{
    padding: 0 0 0 0.34rem;
}
.termsContent >>> *{
    font-size: 0.28rem !important;
}
</style>
<style lang='scss' scoped>
.wrap{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 1.88rem;
    right: 0;
    padding: 0.8rem 0.4rem 0 0.37rem;
    box-sizing: border-box;
    .title{
        width: 100%;
        line-height: 0.6rem;
        font-size: 0.46rem;
        text-align: center;
    }
    .timeBox{
        width: 100%;
        height: 0.54rem;
        margin-top: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        >span{
            display: inline-block;
            height: 0.54rem;
            line-height: 0.54rem;
            padding: 0 0.68rem;
            background: #D7D8D6;
            font-size: 0.23rem;
            color: #000000;
        }
    }
    .termsBox{
        position: absolute;
        top: 2.77rem;
        left: 0.37rem;
        bottom: 0;
        right: 0.4rem;
        
        .progress{
            width: 0.07rem;
            height: 100%;
            background:rgb(216,216,216);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            .progressValue{
                width: 100%;
                background: rgb(219,0,17);
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .termsContent{
            width: 100%;
            padding-left: 0.4rem;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            text-align: left;
            font-size: 0.32rem;
            line-height: 0.48rem;
            &::-webkit-scrollbar{
                    width: 0.03rem;
            }
            &::-webkit-scrollbar-track {
                background-color: rgb(216,216,216);
            }
            &::-webkit-scrollbar-thumb {
                background-color:  rgb(219,0,17); 
            }
            .termsTitle{
                font-size: 0.32rem;
                color: #000000;
                line-height: 0.48rem;
            }
            .termsTxt{
                font-size: 0.28rem;
                line-height: 0.48rem;
                color: #767676;
                margin: 0.15rem 0;
            }
        }
    }
}
.btns{
    width: 100%;
    height: 1.88rem;
    padding: 0.4rem;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.02rem;
    .btn{
        width: 3.27rem;
        height: 1.18rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        &.btn01{
            border:1px solid #000000;
            color: #000000;
            box-sizing: border-box;
            background: #ffffff;
        }
        &.btn02{
            background: #D7D8D6;
            color: #ffffff;
        }
        &.success{
            background: #DB0011;
            color: #ffffff;
            &:active{
                background: #A7140A;
            }
        }
        
    }
    
}
.mask{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.4);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    .pop{
        width: 5.69rem;
        padding: 0.4rem;
        background: #ffffff;
        box-sizing: border-box;
        position: relative;
        .popClose{
            display: block;
            width: 0.2rem;
            height: 0.2rem;
            position: absolute;
            top: 0.24rem;
            right: 0.24rem;
            >img{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .popTitle{
            width: 100%;
            line-height: 0.6rem;
            font-size: 0.46rem;
            font-weight: 500;
            text-align: left;
        }
        .popContent{
            width: 100%;
            margin-top: 0.4rem;
            display: flex;
            justify-content: space-between;
            >i{
                display: block;
                width: 0.24rem;
                height: 0.24rem;
                border: 1px solid #000000;
                box-sizing: border-box;
                margin-top: 0.1rem;
            }
            >input{
                display: block;
                width: 0.24rem;
                height: 0.24rem;
                border: 1px solid #000000;
                box-sizing: border-box;
                margin-top: 0.05rem;
            }
            .box{
                display: block;
                width: 0.24rem;
                height: 0.24rem;
                margin-top: 0.1rem;
            }
            >span{
                width: 4.53rem;
                font-size: 0.28rem;
                line-height: 0.36rem;
            }
        }
        .popBtn{
            width: 100%;
            height: 0.88rem;
            margin-top: 0.4rem;
            background: #D7D8D6;
            font-size: 0.32rem;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active{
                background: #DB0011;
                &:active{
                    background: #A7140A;
                }
            }
            &.success{
                background: #01837E;
            }
            .successIcon{
                display: block;
                width: 0.48rem;
                height: 0.36rem;
            }
            .loadingIcon{
                display: block;
                width: 0.48rem;
                height: 0.48rem;
            }
        }
    }
}
</style>

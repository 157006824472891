<template>
  <div class="container">
    <div class="wrap">
        <warning :warningShow="warningShow" :warningMsg='warningMsg'/>
        <div class="close" @click="back"><img src="@/assets/img/forgot/close.png" alt="close"></div>
        <div class="content">
            <div class="title">Forgotten password</div>
            <div class="fromBox">
                <div class="fromItem">
                    <div class="fromTitle">Please enter your username</div>
                    <div class="fromItemInput">
                        <img src="@/assets/img/forgot/username.png" alt="" class="usernameIcon">
                        <input type="text" name="username" id="username" value="" v-model="username">
                    </div>
                </div>
                <div class="fromItem">
                    <div class="fromTitle">Please enter your HSBC email</div>
                    <div class="fromItemInput">
                        <img src="@/assets/img/forgot/email.png" alt="" class="emailIcon">
                        <input type="text" name="email" id="email" value="" v-model="email">
                    </div>
                </div>
            </div>
        </div>
        <div class="btnBox">
            <button class="btn" @click="reset">Reset password</button>
            <div class="reg" @click="forgot">
                <span>Forgotten username</span>
                <img src="@/assets/img/forgot/arrow.png" alt="" class="regIcon">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import warning from '@/components/warning.vue'
export default {
  components:{
      warning
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          username:'',
          email:'',

          warningShow:false,
          warningMsg:'',
        }
    },
    mounted(){
        var that = this;
        utag_fun(that.$route.name)
    },
    methods:{
        reset:function(){
            var that = this;
            if(!that.username || !that.email){
                return false;
            }
            that.$http.post(that.$api.passwordForget.url, {username:that.username,email:that.email}).then(res => {
                if(res.code == 1){
                    that.$router.push({path:'/forgot/success'})
                }else{
                    that.warningShow = true;
                    that.warningMsg = res.msg;
                    // setTimeout(function(){
                    //     that.warningShow = false;
                    // },2000)
                }
            })
        },
        forgot:function(){
            var that = this;
            that.$router.push({path:'/forgot/username'})
        },
        back:function(){
            var that = this;
            that.$router.push({path:'/logon/on'})
        },
    },
}
</script>

<style lang='scss' scoped>
.wrap{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-top: 1.6rem;
    box-sizing: border-box;
    .close{
        width: 0.2rem;
        height: 0.2rem;
        position: absolute;
        top: 0.24rem;
        right: 0.24rem;
        >img{
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .content{
        width: 100%;
        padding: 0 0.4rem;
        box-sizing: border-box;
        .title{
            width: 100%;
            height: 0.6rem;
            line-height: 0.6rem;
            font-size: 0.46rem;
        }
        .fromBox{
            width: 100%;
            margin-top: 0.8rem;
            .fromItem{
                width: 100%;
                margin-bottom: 0.25rem;
                &:first-child{
                    margin-bottom: 0.3rem;
                }
                .fromTitle{
                    width: 100%;
                    height: 0.41rem;
                    line-height: 0.41rem;
                    font-size: 0.32rem;
                    color: #000000;
                }
                .fromItemInput{
                    width: 100%;
                    height: 0.4rem;
                    padding: 0.24rem 0;
                    border-bottom: 1px solid #000000;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: initial;
                    .usernameIcon{
                        display: block;
                        width: 0.41rem;
                        height: 0.4rem;
                    }
                    .emailIcon{
                        display: block;
                        width: 0.4rem;
                        height: 0.4rem;
                    }
                    >input{
                        width: 6rem;
                        height: 0.4rem;
                        line-height: 0.4rem;
                        font-size: 0.32rem;
                        color: #767676;
                        &::placeholder{
                            color: #767676;
                        }
                    }
                }
            }
        }
    }
    .btnBox{
        width: 100%;
        padding: 0.4rem;
        position: fixed;
        left: 0;
        bottom: 0;
        box-sizing: border-box;
        .btn{
            width: 100%;
            height: 1.18rem;
            border-radius: 0.02rem;
            background: #DB0011;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.32rem;
            color: #ffffff;
            &:active{
                background: #A7140A;
            }
        }
        .reg{
                width: 100%;
                height: 0.29rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.32rem;
                >span{
                    font-size: 0.23rem;
                    color: #000000;
                }
                .regIcon{
                    display: block;
                    width: 0.25rem;
                    height: 0.29rem;
                    margin-left: 0.12rem;
                }
            }
    }
    
}
</style>

<template>
  <div class="container">
    <top :backShow='false'/>
    <div class="homeWrap">
        <div class="bannerBox">
            <div class="banner">
                <div class="swiper-container" v-if="banner">
                    <div class="swiper-wrapper">
                        <router-link class="swiper-slide" v-for="(item,index) in banner" :key="index" :to="{name:'News',query:{id:item.ad_link}}" aria-live='off'>
                            <div class="bannerSlide">
                                <div class="bannerMask"></div>
                                <h1 class="bannerTitle">{{item.ad_name}}</h1>
                                <img :src="item.ad_code" />
                            </div>
                        </router-link>
                    </div>
                    
                </div>
                <div class="swiper-pagination-index"></div>
            </div>
        </div>
        <div class="column">
            <router-link class="columnItem" to="/home/articles">
                <div class="itemBox">
                    <img src="@/assets/img/home/latest_articles.png" alt="Latest articles" class="itemIcon">
                    <span>Latest articles</span>
                </div>
            </router-link>
            <router-link class="columnItem" to="/play">
                <div class="itemBox">
                    <img src="@/assets/img/home/Play_and_share.png" alt="Play and share" class="itemIcon">
                    <span>Play and share</span>
                </div>
                
            </router-link>
            <router-link class="columnItem" to="/webcasts">
                <div class="itemBox">
                    <img src="@/assets/img/home/Well_being_webcasts.png" alt="Well-being webcasts" class="itemIcon">
                    <span>Well-being<br/>webcasts</span>
                </div>
                
            </router-link>
            <router-link class="columnItem" to="/my/points">
                <div class="itemBox">
                    <img src="@/assets/img/home/Points_and_prizes.png" alt="Points and prizes" class="itemIcon">
                    <span>Points and prizes</span>
                </div>
                
            </router-link>  
        </div>
    </div>
    <tabbar/>
  </div>
</template>

<script>
import Swiper from 'swiper';
import '../../assets/css/swiper.min.css';
import tabbar from '@/components/tabbar.vue'
import top from '@/components/top.vue'
export default {
    components:{
      tabbar,
      top
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
            banner:[],
        }
    },
    created() {
		var that = this;
		
	},
    mounted(){
        var that = this;
        that.getBanner();
        utag_fun(that.$route.name)
        
    },
    methods:{
        bindNews:function(id){
            var that = this;
            that.$router.push({name:'News',query:{id:id}})
        },
        getBanner:function(){
            var that = this;
            that.$http.get(that.$api.getActIndex.url, {}).then(res => {
                if(res.code == 1){
                    that.banner = res.data.adList;
                    that.swiper();
                }else{
                    that.$toast(res.msg);
                }
            })
        },
        swiper:function(){
            var that = this;
            that.$nextTick(function(){
                var mySwiper = new Swiper(".swiper-container",{ 
                    pagination: '.swiper-pagination-index',
                    effect: 'coverflow',
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    // autoplay:4000,
                    onSlideChangeEnd:function(){
                        
                            $('.swiper-slide-active').attr({'aria-live':'polite'});
                            $('.swiper-slide-active').focus();
                            $('.swiper-slide-active').siblings().attr({'aria-live':'off'});
                        
                    },
                });
                mySwiper.stopAutoplay()
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                console.log(isiOS)
                if(isiOS){
                     mySwiper.startAutoplay()
                }
               
            })
        }
    },
}
</script>
<style lang='scss' scoped>
.bannerBox{
    width: 100%;
    position: relative;
    .banner{
        width: 100%;
        padding-top: 0.4rem;
        padding-bottom: 0.38rem;
        .swiper-container {
            width: 100%;
            .swiper-slide {
                width: 7.02rem;
                height: 3.86rem;
                .bannerSlide{
                    width: 100%;
                    height: 100%;
                    position: relative;
                    overflow: hidden;
                    .bannerMask{
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(0,0,0,0.5);
                        z-index: 2;
                    }
                    >img{
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }
                    .bannerTitle{
                        width: 100%;
                        line-height: 0.38rem;
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 0.24rem 0.3rem;
                        box-sizing: border-box;
                        color: #ffffff;
                        font-size: 0.32rem;
                        z-index: 3;
                        font-weight: normal;
                    }
                }
                
            }
        }
    }

}
.column{
    width: 100%;
    padding: 0 0.22rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .columnItem{
        width: 3.31rem;
        height: 3.31rem;
        // background: url(../../assets/img/home/column_bg.png) no-repeat;
        background: #000000;
        background-size: 100% 100%;
        margin-bottom: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .itemBox{
            width: 100%;
            .itemIcon{
                display: block;
                width: 1.2rem;
                height: 1.2rem;
                margin: 0 auto;
            }
            >span{
                display: block;
                width: 100%;
                line-height: 0.4rem;
                font-size: 0.32rem;
                color: #ffffff;
                text-align: center;
                margin-top: 0.08rem;
            }
        }
        
    }
}

</style>
<style lang="scss">
.swiper-pagination-index{
    width: 100%;
    bottom: 0;
    height: 0.92rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .swiper-pagination-bullet{
        display: block;
        width: 0.2rem;
        height: 0.2rem;
        border-radius: 50%;
        background: #D8D8D8;
        margin: 0 0.07rem;
        opacity: 1;
        &.swiper-pagination-bullet-active{
            background: #E22219;
        }
    }
}
</style>

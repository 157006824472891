<template>
  <div class="container">
    <top :backShow='true'/>
    <div class="homeWrap">
		<h1 class="title">Points and prizes</h1>
        <div class="content">
            <div class="progress" v-show="progressStatus">
                <div class="progressValue" :style="{height:progress+'%',top:progressTop + '%'}"></div>
            </div>
            <vuescroll @handle-scroll="handleScroll" ref="vs">
                <div class="contentView" v-html="content"></div>
            </vuescroll>
        </div>
    </div>
    <tabbar/>
  </div>
</template>

<script>
import tabbar from '@/components/tabbar.vue'
import top from '@/components/top.vue'
import vuescroll from 'vuescroll';
export default {
    components:{
      tabbar,
      top,
      vuescroll
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          progressStatus:false,
          progress:0,
          progressTop:0,
          content:'',
        }
    },
    mounted(){
        var that = this;
        that.getPage();
        utag_fun(that.$route.name)

    },
    methods:{
        getPage:function(){
            var that = this;
            that.$http.get(that.$api.fixedPage.url, {ctype:'jifen_ruler'}).then(res => {
                if(res.code == 1){
                    that.content = res.data.detail.content;
                    that.$nextTick(function () {
                        that.getScroll();
                    })
                }else{
                    that.$toast(res.msg);
                }
            })
        },
        getScroll:function(){
            var that = this;
            that.progress = that.$refs["vs"].getCurrentviewDom()[0].offsetHeight / that.$refs["vs"].getCurrentviewDom()[0].scrollHeight * 100;
            if(that.progress >= 99){
                that.progressStatus = false;
            }else{
                that.progressStatus = true;
            }
        },
        handleScroll:function(v,h,n){
            var that = this;
            that.progress = v.barSize * 100;
            that.progressTop = v.process * 100 - v.barSize * 100 *  v.process;
        },
    },
}
</script>
<style scoped>
.contentView >>> ul li{
    list-style: disc;
}
.contentView >>> ul{
    padding: 0 0 0 0.34rem;
}
.contentView >>> *{
    font-size: 0.28rem !important;
}
</style>
<style lang='scss' scoped>

.homeWrap{
    width: 100%;
    .title{
        width: 100%;
        padding: 0.13rem 0.24rem 0.14rem;
        box-sizing: border-box;
        font-size: 0.46rem;
        font-weight: 500;
        line-height: 0.6rem;
    }
    .content{
        position: absolute;
        top: 0.87rem;
        left: 0.25rem;
        right: 0.4rem;
        bottom: 0.4rem;
        font-size: 0.28rem;
        color: #767676;
        line-height: 0.38rem;
        .contentView{
            width: 100%;
            height: 100%;
            padding-left: 0.37rem;
            box-sizing: border-box;
            text-align: left;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            font-size: 0.32rem;
            line-height: 0.48rem;
            &::-webkit-scrollbar{
                    width: 0.07rem;
            }
            &::-webkit-scrollbar-track {
                background-color: rgb(216,216,216);
            }
            &::-webkit-scrollbar-thumb {
                background-color:  rgb(219,0,17); 
            }
        }
    }
}
</style>

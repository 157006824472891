import axios from "axios";
import utils from "../assets/js/utils";
axios.defaults.timeout = 50000; // 请求超时
axios.defaults.baseURL = utils.host() + "/api"; // 增加请求默认路径前缀
// axios.defaults.headers.common['token'] =  utils.getCookie('token')?utils.getCookie('token'):'';

axios.defaults.headers.common['Accept-Language'] = 'en-US'

export default {
  /**
   * get 请求
   * @param url 接口路由
   * @returns {AxiosPromise<any>}
   */
  get(url, params, headers) {
    let options = {};
 
    if (params) {
      options.params = params;
    }
    if (headers) {
      options.headers = headers;
      
    }
    axios.defaults.headers.common['csrf-token'] =  utils.getCookie('csrf_token')?utils.getCookie('csrf_token'):'';
    return axios.get(url, options);
  },
 
  /**
   * post 请求
   *
   * @param url 接口路由
   * @param params 接口参数
   * @returns {AxiosPromise<any>}
   */
  post(url, data ,headers) {
    let options = {};
 
    if (headers) {
      options.headers = headers;
    }
    axios.defaults.headers.common['csrf-token'] =  utils.getCookie('csrf_token')?utils.getCookie('csrf_token'):'';
    return axios.post(url, data, options);
  },
};
<template>
  <div class="hf_logo" :class="{logo_position:position}">
    <img src="../assets/img/utils/HSBC&BWF_logo.png" alt="HSBC&BWF logo" width="100%">
  </div>
</template>
<script>
export default {
    props: {
        position:Boolean,
    },
    data(){
        return {
            
        }
    },
    components: {
        
    },
    async mounted(){
        var that = this;
        
    },
    methods:{
        goBack:function(){
            var that = this;
            if(that.backType == 1){
                that.$router.push({path:'/home'})
            }else{
                that.$router.go(-1);
            }
        },
    }
}
</script>
<style lang='scss'>
.hf_logo{
  width: 3.35rem;
  height: 0.81rem;
  position: fixed;
  top: 0.47rem;
  left: 50%;
  margin-left: -1.67rem;
  &.logo_position{
    position: absolute;
  }
  >img{
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>

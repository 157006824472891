<template>
  <div class="container">
    <logo/>
    <div class="from">
        <div class="title">Change password</div>
        <div class="fromBox">
            <div class="fromItem">
                <div class="fromTitle">Please enter your new password</div>
                <div class="fromItemInput"  :class="{warning:warning}">
                    <input type="password" name="newpassword" id="newpassword" value="" v-model="newpassword">
                </div>
            </div>
            <div class="fromItem">
                <div class="fromTitle">Please confirm your new password</div>
                <div class="fromItemInput"  :class="{warning:warning}">
                    <input type="password" name="newpassword_confirm" id="newpassword_confirm" value="" v-model="newpassword_confirm">
                </div>
            </div>
            <div class="warning" v-if="warning">
                <img src="@/assets/img/logon/warning.png" alt="" class="warningIcon">
                <span :aria-live="alive">{{amsg}}</span>
            </div>
            <div class="tips">Your password must include: between 8-12 characters, with at least one upper case letter, one lower case letter, one number or one of these special characters: !@#$%^* or both.</div>
        </div>
    </div>
    <div class="btns">
        <button class="btn" @click="reset" v-if="btnStatus == 0">Reset password</button>
        <button class="btn" v-if="btnStatus == 1">
            <img src="@/assets/img/logon/loading.gif" alt="" class="loadingIcon">
        </button>
        <button class="btn success" v-if="btnStatus == 2">
            <img src="@/assets/img/logon/success.png" alt="" class="successIcon">
        </button>
    </div>
  </div>
</template>

<script>
import logo from '@/components/logo.vue'
export default {
    components:{
        logo
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
            warning:false,
            newpassword:'',
            newpassword_confirm:'',
            ckey:'',
            btnStatus:0,
            alive:'off',
            amsg:'',
        }
    },
    mounted(){
        var that = this;
        var ckey = that.$route.query.ckey;
        that.ckey = ckey;
    },
    methods:{
        reset:function(){
            var that = this;
            if(that.newpassword != that.newpassword_confirm){
                that.amsg = 'Your password entered does not match, please check and enter again.';
                that.warning = true;
                that.alive = 'polite';
                return false;
            }
            that.btnStatus = 1;
            that.$http.post(that.$api.ckeyPassword.url, {newpassword:that.newpassword,ckey:that.ckey}).then(res => {
                if(res.code == 1){
                    that.$utils.delCookie('need_utype');
                    //that.$utils.delCookie('token');
                    
                    setTimeout(function(){
                        that.btnStatus = 2;
                        that.$toast(res.msg);
                    },1000)
                    setTimeout(function(){
                        that.$router.push({path:'/logon/on'})
                    },2000)
                    
                    
                }else{
                    that.amsg = res.msg;
                    that.warning = true;
                    that.alive = 'polite';
                    return false;
                }
            })
        },
        back:function(){
            var that = this;
            that.$router.go(-1);
        },
    },
}
</script>

<style lang='scss' scoped>
.close{
    width: 0.4rem;
    height: 0.4rem;
    position: fixed;
    top: 0.24rem;
    right: 0.24rem;
    z-index: 10;
}
.from{
    width: 100%;
    position: fixed;
    top: 1.6rem;
    left: 0;
    right: 0;
    bottom: 1.98rem;
    padding: 0 0.4rem;
    box-sizing: border-box;
    .title{
        width: 100%;
        font-size: 0.46rem;
        line-height: 0.6rem;
        font-weight: 500;
    }
    .fromBox{
        width: 100%;
        margin-top: 0.8rem;
        .fromItem{
            width: 100%;
            margin-bottom: 0.25rem;
            &:first-child{
                margin-bottom: 0.3rem;
            }
            .fromTitle{
                width: 100%;
                height: 0.41rem;
                line-height: 0.41rem;
                font-size: 0.32rem;
                color: #000000;
            }
            .fromItemInput{
                width: 100%;
                height: 0.4rem;
                padding: 0.24rem 0;
                border-bottom: 1px solid #000000;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: initial;
                &.warning{
                        border-bottom: 1px solid #A8000B;
                    }
                .usernameIcon{
                    display: block;
                    width: 0.41rem;
                    height: 0.4rem;
                }
                .PasswordIcon{
                    display: block;
                    width: 0.36rem;
                    height: 0.4rem;
                }
                >input{
                    width: 6rem;
                    height: 0.4rem;
                    line-height: 0.4rem;
                    font-size: 0.32rem;
                    color: #767676;
                    &::placeholder{
                        color: #767676;
                    }
                }
            }
        }
        .warning{
            width: 100%;
            height: 0.36rem;
            display: flex;
            align-items: center;
            .warningIcon{
                display: block;
                width: 0.4rem;
                height: 0.36rem;
            }
            >span{
                margin-left: 0.16rem;
                font-size: 0.19rem;
                color: #000000;

            }
        }
        .tips{
            width: 100%;
            margin-top: 0.24rem;
            font-size: 0.19rem;
            color: #767676;
            line-height: 0.27rem;
            word-wrap: normal;
            word-break: keep-all;
        }
    }
}
.btns{
    width: 100%;
    padding: 0.4rem;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    .btn{
        width: 100%;
        height: 1.18rem;
        border-radius: 0.02rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #DB0011;
        font-size: 0.32rem;
        color: #ffffff;
        &:active{
              background: #A7140A;
          }
        &.success{
            background: #01837E;
        }
        .successIcon{
            display: block;
            width: 0.48rem;
            height: 0.36rem;
        }
        .loadingIcon{
            display: block;
            width: 0.48rem;
            height: 0.48rem;
        }
    }
}
</style>

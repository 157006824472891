<template>
  <div class="headerWrap">
      <img src="@/assets/img/utils/HSBC_logo.png" alt="" class="headerLogo" @click="back">
      <button name="back" class="headerBack" @click="goBack" v-if="backShow" aria-label="back"><van-icon name="arrow-left" color='#ffffff' size='0.5rem'/></button>
  </div>
</template>
<script>
export default {
    props: {
        backShow:Boolean,
        backType:Boolean,
    },
    data(){
        return {
            
        }
    },
    components: {
        
    },
    async mounted(){
        var that = this;
        that.$nextTick(function(){
                 $('.headerBack').focus();
            })
    },
    methods:{
        back:function(){
            var that = this;
            var userinfo = JSON.parse(that.$utils.getCookie("need_utype"));
            if(userinfo.utype == 1){
                that.$router.push({path:'/home'})
            }else{
                that.$router.push({path:'/my/family'})
            }
        },
        goBack:function(){
            var that = this;
            var userinfo = JSON.parse(that.$utils.getCookie("need_utype"));
            if(that.backType == 1){
                if(userinfo.utype == 1){
                    that.$router.push({path:'/home'})
                }else{
                    that.$router.push({path:'/my/family'})
                }
            }else{
                that.$router.go(-1);
            }
        },
    }
}
</script>
<style lang='scss'>
.headerWrap{
    width: 100%;
    height: 0.88rem;
    background: rgb(37,48,56);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    .headerLogo{
        display: block;
        width: 0.64rem;
        height: 0.32rem;
    }
    .headerBack{
        width: 0.6rem;
        height: 0.6rem;
        position: absolute;
        top: 50%;
        margin-top: -0.3rem;
        left: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>

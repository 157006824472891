 
export default {
    host:function(){
        //return '//hsbcbadminton-uat.internad.hk';
        //return '//badminton.hsbc.com'
        //return '//hf.xiaozaoyun.com';
        //return '//hub.xiaozaoyun.com';
        return '//badminton.wiredcraft.net';
    },
    setCookie:function(c_name, value, expirehours,samesite) {
            if(expirehours == 'Infinity'){
                document.cookie = c_name + "=" + value + "; path=/;expires = Fri, 31 Dec 9999 23:59:59 GMT";
            }else{
                var exdate = new Date();
                exdate.setTime(exdate.getTime() + Number(expirehours?expirehours:1) * 3600 * 1000);
                if(samesite){
                    document.cookie = c_name + "=" + value + "; path=/;expires = " + exdate.toGMTString()+";secure=true;SameSite=Strict;";
                }else{
                    document.cookie = c_name + "=" + value + "; path=/;expires = " + exdate.toGMTString()+";";
                }
                
            }
            
    },
    getCookie:function(name) {
        var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        if (arr = document.cookie.match(reg)){
               return (arr[2]);
      }else{
            return false
        }
    },
    delCookie:function(name) {
        var exp = new Date();
        exp.setTime(exp.getTime() - 3);
        var cval = this.getCookie(name);
        if (cval){
             document.cookie = name + "=" + cval + "; path=/;expires=" + exp.toGMTString();
        }
    },
    JsonToString:function (o) {    
        var arr = []; 
        var fmt = function(s) { 
            if (typeof s == 'object' && s != null) return JsonToStr(s); 
            return /^(string|number)$/.test(typeof s) ? "'" + s + "'" : s; //如果不想过滤数字改为 return /^(string)$/.test(typeof s) ? '"' + s + '"' : s;
        } 
        for (var i in o) 
            arr.push("'" + i + "':" + fmt(o[i])); 
        return '{' + arr.join(',') + '}'; 
    },
    loadUtagSync:function(){
        var head = document.head || document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = "text/javascript";
        script.setAttribute("src",'//tags.tiqcdn.com/utag/hsbc/global-common-hsbchistorywebsite/prod/utag.sync.js');
        head.appendChild(script);
    },
    loadUtag:function(){
        (function(a,b,c,d){    
            a='//tags.tiqcdn.com/utag/hsbc/global-common-hsbchistorywebsite/prod/utag.js';
            b=document;c='script';d=b.createElement(c);d.src=a;
            d.type='text/java'+c;d.async=true;
            a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
          })();
    }
}

<template>
  <div class="container">
    <top :backShow='true' :backType='true'/>
    <div class="homeWrap" @scroll="handleScroll($event)">
		<h1 class="title">Well-being webcasts</h1>
        <div class="banner">
            <div class="bannerBox">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <router-link class="swiper-slide" v-for="(item,index) in banner" :key="index" :to="{name:'News',query:{id:item.ad_link}}"  aria-live='off'>
                            <img :src="item.ad_code" />
                            <div class="slideBox">
                                <div class="slideBoxContent">
                                    <h1>{{item.ad_name}}</h1>
                                    <h2>{{item.ad_description}}</h2>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="swiper-pagination-art"></div>
            </div>
        </div>
        <div class="btns" v-if="activityList.length > 0">
            <button @click="slideBoxClick" v-if="playStatus == 0" aria-label="Play scrolling text">
                <van-icon name="play-circle-o" class="play_icon" />
            </button>
            <button @click="slideBoxClick" v-if="playStatus == 1"  aria-label="Pause scrolling text">
                <van-icon name="pause-circle-o" class="play_icon" />
            </button>
            <div class="btn btnBlack">
                <div class="slideBox btnSlide slideBoxAni" >
                    <span v-for="(item,index) in activityList" :key="index" class="scrollLi">{{item.title}}</span>
                </div>
            </div>
            
        </div>
        <div class="btns2 btns"  v-if="act">
            <div class="btn btnRed" @click="contact">{{isSignUp==0?'Sign up':'Withdraw my sign up'}}</div>
        </div>
        <div class="list">
            <div class="item" v-for="(item,index) in webcasts" :key="index" >
                <h2 class="itemTitle">{{item.title}}</h2>
                <router-link class="itemPic" :to="{name:'News',query:{id:item.id}}">
                    <img :src="item.image" alt="" width="100%">
                </router-link>
                <div class="itemLabel">
                    <router-link :to="{name:'News',query:{id:item.id}}" class="itemLabelLink">{{item.description}}</router-link>
                    <div class="like">
                        <i v-if="item.is_like == 0" id="like_label">Like</i>
                        <i v-if="item.is_like == 1"  id="like_label">Unlike</i>
                        <!-- :aria-label='item.is_like == 1?"liked":"unliked"' -->
                        <button aria-describedby='like_label' >
                        <img src="@/assets/img/utils/star_on.png" alt="" class="likeIcon" v-if="item.is_like == 1" @click="unlike(item.id,index)">
                        <img src="@/assets/img/utils/star_none.png" alt="" class="likeIcon" v-if="item.is_like == 0" @click="like(item.id,index)">
                        </button>
                        <em>{{item.like_num}}</em>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <tabbar/>
  </div>
</template>

<script>
import Swiper from 'swiper';
import '../../assets/css/swiper.min.css';
import tabbar from '@/components/tabbar.vue'
import top from '@/components/top.vue'
export default {
    components:{
      tabbar,
      top
    },
    head(){
        return {
            title:''
        }
    },
    created() {
		
	},
    data(){
        return {
          id:0,
          isSignUp:0,
          banner:[],
          webcasts:[],
          activityList:[],
          curActInfo:{},
          act:false,
          page:1,
          allpage:1,
          flag:true,
          options: {
            mainCell: ".bd ul",
            autoPlay: true,
            effect:"leftMarquee",
            interTime:25,
          },
          aniStatus:1,
          playStatus:1,
        }
    },
    computed: {
     classOption () {
       return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
    mounted(){
        var that = this;
        that.getData();
        utag_fun(that.$route.name)

    },
    methods:{
        like:function(id,index){
            var that = this;
            if(that.flag){
                that.flag = false;
                that.$http.post(that.$api.articleLike.url, {id:id}).then(res => {
                    if(res.code == 1){
                        that.webcasts[index].is_like = 1;
                        that.webcasts[index].like_num++;
                        that.flag = true;
                    }else{
                        that.$toast(res.msg);
                        that.flag = true;
                    }
                })
            }
            
        },
        unlike:function(id,index){
            var that = this;
            if(that.flag){
                that.flag = false;
                that.$http.post(that.$api.articleUnLike.url, {id:id}).then(res => {
                    if(res.code == 1){
                        that.webcasts[index].is_like = 0;
                        that.webcasts[index].like_num--;
                        that.flag = true;
                    }else{
                        that.$toast(res.msg);
                        that.flag = true;
                    }
                })
            }
            
        },
        handleScroll:function(e){
           var that = this;
           var clientHeight = e.target.clientHeight;
           var scrollTop = e.target.scrollTop;
           var scrollHeight = e.target.scrollHeight;
           if(clientHeight + scrollTop == scrollHeight){
               that.getData()
           }
        },
        getData:function(){
            var that = this;
            if(that.page > that.allpage){
                return false;
            }
            that.$http.get(that.$api.webcasts.url, {page:that.page}).then(res => {
                if(res.code == 1){
                    that.banner = res.data.adList;
                    that.webcasts = that.webcasts.concat(res.data.webcasts.lists);
                    that.page++;
                    that.allpage = res.data.webcasts.pages.allpage;
                    that.activityList = res.data.activityList;
                    if(res.data.curActInfo.length == 0){
                        that.act = false;
                    }else{
                        that.act = true;
                        that.curActInfo = res.data.curActInfo;
                        that.isSignUp = res.data.curActInfo.isSignUp;
                        that.id = res.data.curActInfo.id;
                    }
                    
                    that.swiper();
                }else{
                that.$toast(res.msg);
                }
            })
        },
        swiper:function(){
            var that = this;
            that.$nextTick(function(){
                var mySwiper = new Swiper(".swiper-container",{ 
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    loop:true,
                    // autoplay:4000,
                    pagination: {
                        el: '.swiper-pagination-art',
                        clickable: true,
                    },
                    onSlideChangeEnd:function(){
                        
                            $('.swiper-slide-active').attr({'aria-live':'polite'});
                            $('.swiper-slide-active').focus();
                            $('.swiper-slide-active').siblings().attr({'aria-live':'off'});
                        
                    },
                });
                mySwiper.stopAutoplay()
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                console.log(isiOS)
                if(isiOS){
                     mySwiper.startAutoplay()
                }
                that.ani();
            })
        },
        slideBoxClick:function(){
            var that = this;
            if(that.aniStatus == 1){
                that.aniStatus = 0;
                $('.btnSlide').stop();
                $('.btnSlide').removeClass('slideBoxAni');
                that.playStatus = 0;
            }else{
                that.aniStatus = 1;
                that.playStatus = 1;
                $('.btnSlide').addClass('slideBoxAni');
                that.ani();
            }
            
        },
        ani:function(){
            var that = this;
            var width = $('.slideBoxAni span').width() - $('.btnBlack').width();
            $('.slideBoxAni').animate({"left":'-'+width+'px'},6000,'linear',function(){
                    $('.slideBoxAni').css({left:0});
                    setTimeout(function(){
                        that.ani();
                    },500)
            })
        },
        contact:function(){
            var that = this;
            that.$router.push({path:'/webcasts/contact',query:{isSignUp:that.isSignUp,id:that.id}})
        },
        bindNews:function(id){
            var that = this;
            that.$router.push({name:'News',query:{id:id}})
        },
    },
}
</script>

<style lang='scss' scoped>
.homeWrap{
    .title{
        width: 100%;
        padding: 0.16rem 0.24rem 0;
        box-sizing: border-box;
        font-size: 0.46rem;
        font-weight: 500;
        line-height: 0.6rem;
    }
    .banner{
        width: 100%;
        margin-top: 0.08rem;
        .bannerTitle{
            width: 100%;
            height: 0.48rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.24rem;
            box-sizing: border-box;
            >span{
                font-size: 0.32rem;
            }
            .arrowIcon{
                display: block;
                width: 0.18rem;
                height: 0.21rem;
            }
        }
        .bannerBox{
            width: 100%;
            padding-left: 0.24rem;
            box-sizing: border-box;
            margin-top: 0.16rem;
            position: relative;
            .swiper-container {
                width: 100%;
                .swiper-slide {
                    width: 6.69rem;
                    height: 3.68rem;
                    position: relative;
                    >img{
                        width: 100%;
                    }
                    .slideBox{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: rgba(0,0,0,0.5);
                        padding: 0.24rem 0.25rem;
                        box-sizing: border-box;
                        .slideBoxContent{
                            width: 100%;
                            height: 100%;
                            color: #ffffff;
                            >h1{
                                font-size: 0.32rem;
                                line-height: 0.38rem;
                                margin-bottom: 0.12rem;
                            }
                            >h2{
                                font-size: 0.18rem;
                                line-height: 0.23rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .btns{
        width: 100%;
        margin-top: 0.32rem;
        padding: 0 0.24rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.btns2{
            margin-top: 0;
        }
        .play_icon{
            font-size: 0.5rem;
        }
        .btn{
            width: 90%;
            height: 0.93rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.32rem;
            border-radius: 0.02rem;
            &.btnBlack{
                border: 1px solid #000000;
                color: #000000;
                box-sizing: border-box;
                overflow: hidden;
                text-align: center;
                overflow-x: auto;
                position: relative;
                .slideBox{
                    height: 100%;
                    white-space: nowrap;
                    padding: 0 0.15rem;
                    box-sizing: border-box;
                    position: absolute;
                    top: 0;
                    left: 0;
                    .scrollLi{
                        display: inline-block;
                        height: 100%;
                        line-height: 0.93rem;
                    }
                }
            }
            &.btnRed{
                background: #E22219;
                color: #ffffff;
                margin: 0.24rem auto 0;
            }
        }
    }
    .list{
        width: 100%;
        margin-top: 0.32rem;
        padding: 0 0.24rem;
        box-sizing: border-box;
        .item{
            width: 100%;
            margin-bottom: 0.32rem;
            .itemTitle{
                display: block;
                width: 100%;
                line-height: 0.41rem;
                font-size: 0.32rem;
                font-weight: 500;
            }
            .itemPic{
                display: block;
                width: 100%;
                height: 2.5rem;
                margin-top: 0.12rem;
                overflow: hidden;
                >img{
                    width: 100%;
                }
            }
            .itemLabel{
                width: 100%;
                margin-top: 0.12rem;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                .itemLabelLink{
                    display: block;
                    width: 5.2rem;
                    font-size: 0.28rem;
                    color: #999999;
                    line-height: 0.36rem;
                }
                .like{
                    height: 0.37rem;
                    display: flex;
                    align-items: center;
                    >i{
                        font-size: 0.19rem;
                        color: #9B9B9B;
                        margin-right: 0.07rem;
                        white-space: nowrap;
                    }
                    .likeIcon{
                        display: block;
                        width: 0.44rem;
                        height: 0.37rem;
                    }
                    >em{
                        font-size: 0.19rem;
                        color: #9B9B9B;
                        margin-left: 0.07rem;
                    }
                }
            }
        }
    }
    .share{
        width: 0.96rem;
        height: 0.96rem;
        position: fixed;
        bottom: 1.15rem;
        right: 0.16rem;
        z-index: 5;
    }
    .table-content{
       width: 100%;
       height: 50%;
       overflow: hidden;
       
    }
    .scrollUl{
        width: 200%;
    }
    .scrollLi{
        height: 0.4rem;
        float: left;
        margin-right: 0.1rem;
        white-space: nowrap;
        line-height: 0.4rem;
    }
}
</style>

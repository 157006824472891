<template>
  <div class="container">
    <div class="wrap">
		<logo/>
        <div class="shareBox">
            <img src="@/assets/img/webcasts/email.png" alt="" class="shareIcon">
            <span>Your password has been changed successfully. Please check your HSBC mailbox.</span>
        </div>
        <div class="btns">
          <div class="btn btnRed" @click="back">Back to log on page</div>
        </div>
    </div>
  </div>
</template>

<script>
import logo from '@/components/logo.vue'
export default {
    components:{
      logo
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          
        }
    },
    mounted(){
        var that = this;
        var location_url = window.location.href;
        utag_fun(that.$route.name)
    },
    methods:{
        back:function(){
            var that = this;
            that.$router.push({path:'/logon/on'})
        }
    },
}
</script>

<style lang='scss' scoped>
.wrap{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3.48rem;
    box-sizing: border-box;
    .shareBox{
        width: 100%;
        padding: 0 0.4rem;
        box-sizing: border-box;
        .shareIcon{
            display: block;
            width: 1.2rem;
            height: 0.94rem;
            margin: 0 auto;
        }
        >span{
            display: block;
            width: 100%;
            margin-top: 0.8rem;
            font-size: 0.32rem;
            line-height: 0.48rem;
            text-align: center;
        }
    }
    .btns{
        width: 100%;
        padding: 0.4rem;
        box-sizing: border-box;
        position: fixed;
        left: 0;
        bottom: 0;
        .btn{
            width: 100%;
            height: 1.18rem;
            border-radius: 0.02rem;
            font-size: 0.32rem;
            display: flex;
            align-items: center;
            justify-content: center;
            &.btnRed{
            background: #DB0011;
            color: #ffffff;
            &:active{
              background: #A7140A;
          }
            }
            &.btnBlack{
            border: 0.02rem solid #000000;
            color: #000000;
            margin-top: 0.32rem;
            }
        }
    }
}
</style>

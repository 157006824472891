<template>
  <div class="container">
    <logo></logo>      
    <div class="indexWrap">
        <div>
            <p>Coming soon!</p>
            <p>For more information, please contact</p>
            <p><a href="mailto:global.brand.partnerships@hsbc.com">global.brand.partnerships@hsbc.com</a></p>
        </div>
    </div>
  </div>
</template>

<script>
import logo from '@/components/logo.vue'
export default {
    components:{
        logo
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          
        }
    },
    mounted(){
        var that = this;
    },
    methods:{
    },
}
</script>

<style lang='scss' scoped>
.indexWrap{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    >div{
        >p{
            font-size: 0.26rem;
            line-height: 0.6rem;
            >a{
                color: #1875f0;
                text-decoration: underline;
            }
        }
    }
    
}
</style>

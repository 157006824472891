<template>
  <div class="container">
    <div class="wrap">
        <warning :warningShow="warningShow" :warningMsg='warningMsg'/>
        <div class="title">Connect, share and <br/>explore with us.</div>
        <div class="fromBox">
            <div class="fromItem">
                <div class="fromItemInput">
                    <img src="@/assets/img/sign/email.png" alt="" class="emailIcon">
                    <input type="text" name="email" id="email" value="" placeholder="HSBC email (required)" v-model="email" @change="checkFrom" :aria-invalid='emailStatus' aria-describedby='msg_label'>
                </div>
            </div>
            <div class="fromItem">
                <div class="fromItemInput">
                    <img src="@/assets/img/sign/email.png" alt="" class="emailIcon">
                    <input type="text" name="email_confirm" id="email_confirm" value="" placeholder="Re-confirm HSBC email (required)" v-model="email_confirm" @change="checkFrom" :aria-invalid='emailStatus' aria-describedby='msg_label'>
                </div>
            </div>
             <div class="fromItem" v-if="email_confirm.indexOf('noexternalmail.hsbc.com') != -1">
                <div class="fromItemInput">
                    <img src="@/assets/img/sign/email.png" alt="" class="emailIcon">
                    <input type="text" name="personal_email" id="personal_email" value="" placeholder="Personal email (required)" v-model="personal_email" @change="checkFrom" :aria-invalid='emailStatus' aria-describedby='msg_label'>
                </div>
                <div class="fromTips" id="personal_email_tips">Due to HSBC mail server limitation, if you are using a no external mail account, please also provide your personal email to receive Hub’s updates and communication.</div>
            </div>
            <div class="fromItem" v-if="email_confirm.indexOf('noexternalmail.hsbc.com') != -1">
                <div class="fromItemInput">
                    <img src="@/assets/img/sign/email.png" alt="" class="emailIcon">
                    <input type="text" name="personal_email_confirm" id="personal_email_confirm" value="" placeholder="Re-confirm your personal email (required)" v-model="personal_email_confirm" @change="checkFrom" :aria-invalid='emailStatus' aria-describedby='msg_label'>
                </div>
            </div>
            <div class="fromItem">
                <div class="fromItemInput">
                    <img src="@/assets/img/sign/username.png" alt="" class="usernameIcon">
                    <input type="text" name="username" id="username" value="" placeholder="New login username (required)" v-model="username" @change="checkFrom" :aria-invalid='usernameStatus' aria-describedby='msg_label'>
                </div>
            </div>
            <div class="fromItem">
                <div class="fromItemInput">
                    <img src="@/assets/img/sign/family_code.png" alt="" class="familyIcon">
                    <select name="market_code" id="market_code" v-model="market_code" @change="checkFrom" aria-describedby="market_tips" :aria-invalid='marketStatus'>
                        <option value="" disabled selected style="display:none;">Type of account (required)</option>
                        <option value="2">HSBC employee account</option>
                        <option value="1">Supplementary family account</option>
                    </select>
                    <i></i>
                </div>
                <p class="fromTips" aria-discribedb='tip' id="market_tips">
                    Please choose “Supplementary family account” if you are registering for a family member.
                </p>
            </div>
            <div class="fromItem">
                <div class="fromItemInput">
                    <img src="@/assets/img/sign/referral_code.png" alt="" class="codeIcon">
                    <input type="text" name="referral_code" id="referral_code" value="" placeholder="Referral code (optional)" v-model="referral_code" :aria-invalid='referralStatus' aria-describedby='msg_label'>
                </div>
            </div>
            <div class="fromItem">
                <div class="fromItemInput">
                    <img src="@/assets/img/sign/password.png" alt="" class="passwordIcon">
                    <input type="password" name="password" id="password" value="" placeholder="Create password" v-model="password" @change="checkFrom" :aria-invalid='passwordStatus' aria-describedby='msg_label'>
                </div>
            </div>
            <div class="fromItem">
                <div class="fromItemInput">
                    <img src="@/assets/img/sign/password.png" alt="" class="passwordIcon">
                    <input type="password" name="password_true" id="password_true" value="" placeholder="Re-confirm password"  v-model="password_confirm"  @change="checkFrom" aria-describedby="password_tips" :aria-invalid='password_trueStatus'>
                </div>
                <div class="fromTips" id="password_tips">Your password must include: between 8-12 characters, with at least one upper case letter, one lower case letter, one number or one of these special characters: !@#$%^* or both.</div>
            </div>
        </div>
        <div class="privacyBox">
            <router-link to="/sign_up/policy" class="privacy">Privacy policy</router-link>
        </div>
    </div>
    <div class="btns">
        <button class="btn btn01" @click="prev">Back</button>
        <button class="btn btn02 " :class="{btnActive:nextBtn,none:!nextBtn}" @click="next" :aria-disabled='nextBtnStatus'>Register</button>
    </div>
  </div>
</template>

<script>
import warning from '@/components/warning.vue'
export default {
    components:{
      warning
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          nextBtn:false,
          nextBtnStatus:'true',
          email:'',
          email_confirm: '',
          personal_email:'',
          personal_email_confirm: '',
          username:'',
          password:'',
          password_confirm:'',
          market_code:'',
          referral_code:'',
          warningShow:false,
          warningMsg:'',
          emailStatus:'false',
          usernameStatus:'false',
          passwordStatus:'false',
          marketStatus:'false',
          referralStatus:'false',
          password_trueStatus:'false',
        }
    },
    mounted(){
        var that = this;
        that.check();
        utag_fun(that.$route.name)
    },
    methods:{
        check:function(){
            var that = this;
            that.$http.get(that.$api.getUserInfo.url, {}).then(res => {})
        },
        errFun:function(id){
            var that = this;
            that.emailStatus='false';
            that.usernameStatus='false';
            that.passwordStatus='false';
            that.marketStatus='false';
            that.referralStatus='false';
            that.password_trueStatus='false';
            if(id == 'email'){
                that.emailStatus = 'true';
                $('#email').focus();
            }
            if(id == 'username'){
                that.usernameStatus = 'true';
                $('#username').focus();
            }
            if(id == 'password'){
                that.passwordStatus = 'true';
                $('#password').focus();
            }
            if(id == 'referral_code'){
                that.referralStatus = 'true';
                $('#referral_code').focus();
            }
        },
        next:function(){
            var that = this;
            if(!that.nextBtn){
                return false;
            }
            if(!that.email){
                that.warningShow = true;
                that.warningMsg = 'Required fields cannot be blank, please fill in and try again.';
                // setTimeout(function(){
                //     that.warningShow = false;
                // },3000)
                return false;
            }
            if(!that.email_confirm){
                that.warningShow = true;
                that.warningMsg = 'Required fields cannot be blank, please fill in and try again.';
                return false;
            }
            if(that.email != that.email_confirm){
                that.warningShow = true;
                that.warningMsg = 'The entered HSBC emails do not match, please check and enter again.';
                return false;
            }
            if(that.email.indexOf('noexternalmail.hsbc.com') != -1 && !that.personal_email){
                that.warningShow = true;
                that.warningMsg = 'Required fields cannot be blank, please fill in and try again.';
                return false;
            }
            if(that.email.indexOf('noexternalmail.hsbc.com') != -1 && !that.personal_email_confirm){
                that.warningShow = true;
                that.warningMsg = 'Required fields cannot be blank, please fill in and try again.';
                return false;
            }
            if(that.email.indexOf('noexternalmail.hsbc.com') != -1 && that.personal_email != that.personal_email_confirm){
                that.warningShow = true;
                that.warningMsg = 'The entered personal emails do not match, please check and enter again.';
                return false;
            }
            if(!that.username){
                that.warningShow = true;
                that.warningMsg = ' Required fields cannot be blank, please fill in and try again.';
                return false;
            }
            if(!that.password){
                that.warningShow = true;
                that.warningMsg = 'Required fields cannot be blank, please fill in and try again.';
                return false;
            }
            if(!that.password_confirm){
                that.warningShow = true;
                that.warningMsg = 'Required fields cannot be blank, please fill in and try again.';
                return false;
            }
            if(that.password != that.password_confirm){
                that.warningShow = true;
                that.warningMsg = 'The entered passwords do not match, please check and enter again.';
                that.errFun('password')
                return false;
            }
            var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,12}$/;
            if(!reg.test(that.password)){
                that.warningShow = true;
                that.warningMsg = 'Invalid password entry, please ensure it meets HSBC password policy requirements and try again.';
                that.errFun('password')
                return false;
            }
            
            var query = {email:that.email,username:that.username,password:that.password,market_code:that.market_code,referral_code:that.referral_code,personal_email:that.personal_email};
            that.checkReg(query)            
        },
        checkReg:function(query){
            var that = this;
            that.$http.post(that.$api.register.url, query).then(res => {
                if(res.code == 1){
                    that.$router.push({path:'/sign_up/success'})
                }else{
                    that.warningShow = true;
                    that.warningMsg = res.msg;
                    if(res.data.efield){
                        that.errFun(res.data.efield)
                    }
                    
                }
            })
        },
        prev:function(){
            var that = this;
            that.$router.go(-1);
        },
        checkFrom:function(){
            var that = this;
            if(that.email && that.username && that.password && that.password_confirm && that.market_code){
                that.nextBtn = true;
                that.nextBtnStatus = 'false';
            }else{
                that.nextBtn = false;
                that.nextBtnStatus = 'true';
            }
        },
        policy:function(){
            var that = this;
            that.$router.push({path:'/sign_up/policy'})
        },
    },
}
</script>

<style lang='scss' scoped>
.wrap{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 1.88rem;
    right: 0;
    padding: 0.8rem 0.37rem 0 0.4rem;
    box-sizing: border-box;
    overflow-y: auto;
    .title{
        font-size: 0.46rem;
        line-height: 0.6rem;
    }
    .fromBox{
            width: 100%;
            margin-top: 0.42rem;
            .fromItem{
                width: 100%;
                margin-bottom: 0.63rem;
                &:last-child{
                    margin-bottom: 0.4rem;
                }
                .fromTitle{
                    width: 100%;
                    height: 0.41rem;
                    line-height: 0.41rem;
                    font-size: 0.32rem;
                    color: #000000;
                }
                .fromItemInput{
                    width: 100%;
                    height: 0.4rem;
                    padding: 0.24rem 0;
                    border-bottom: 1px solid #000000;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: initial;
                    position: relative;
                    .usernameIcon{
                        display: block;
                        width: 0.4rem;
                        height: 0.4rem;
                    }
                    .emailIcon{
                        display: block;
                        width: 0.4rem;
                        height: 0.4rem;
                    }
                    .codeIcon{
                        display: block;
                        width: 0.36rem;
                        height: 0.34rem;
                    }
                    .passwordIcon{
                        display: block;
                        width: 0.4rem;
                        height: 0.4rem;
                    }
                    .familyIcon{
                        display: block;
                        width: 0.4rem;
                        height: 0.4rem;
                    }
                    >input{
                        width: 6rem;
                        height: 0.4rem;
                        line-height: 0.4rem;
                        font-size: 0.32rem;
                        color: #767676;
                        &::placeholder{
                            color: #767676;
                        }
                    }
                    >i{
                        display: block;
                        width: 0.3rem;
                        height: 0.15rem;
                        background: url(../../assets/img/sign/list_arrow.png) no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        top: 50%;
                        margin-top: -0.07rem;
                        right: 0;
                    }
                    >select{
                        width: 6rem;
                        height: 0.4rem;
                        line-height: 0.4rem;
                        font-size: 0.32rem;
                        color: #767676;
                        background: transparent;
                    }
                }
                .fromTips{
                    width: 100%;
                    margin-top: 0.23rem;
                    font-size: 0.19rem;
                    color: #767676;
                    line-height: 0.27rem;
                    word-wrap: normal;
                    word-break: keep-all;
                }
            }
        }
    .privacyBox{
        width: 100%;
        height: 0.32rem;
        display: flex;
        align-items: center;
        margin-bottom: 0.2rem;
        .privacy{
            display:inline-block;
            font-size: 0.24rem;
            color: #282014;
            text-decoration: underline;
        }
    }
    
}
.btns{
    width: 100%;
    height: 1.88rem;
    padding: 0.4rem;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.02rem;
    .btn{
        width: 3.27rem;
        height: 1.18rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        &.btn01{
            border:1px solid #000000;
            color: #000000;
            box-sizing: border-box;
            background: #ffffff;
        }
        &.btn02{
            background: #DB0011;
            color: #ffffff;
        }
        &.none{
            background: #D7D8D6;
            color: #ffffff;
        }
        &.btnActive{
            &:active{
              background: #A7140A;
          }
        }
    }
    .errorTips{
        width: 100%;
        
    }
}
</style>

<template>
  <div class="container">
    <top :backShow='true'/>
    <div class="homeWrap">
		<div class="gameBox">
            <div class="gameTitle">Coming soon…</div>
            <img src="@/assets/img/play/kv.png" alt="" class="gameBg">
        </div>
    </div>
    <tabbar/>
  </div>
</template>

<script>
import tabbar from '@/components/tabbar.vue'
import top from '@/components/top.vue'
export default {
    components:{
      tabbar,
      top
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          
        }
    },
    mounted(){
        var that = this;
        var userinfo = JSON.parse(that.$utils.getCookie('need_utype'));
        var location_url = window.location.href;
        if(userinfo.utype == 1){
            utag_fun(that.$route.name,1)
        }else{
             utag_fun(that.$route.name,2)
        }
        

    },
    methods:{
    },
}
</script>

<style lang='scss' scoped>
.homeWrap{
    width: 100%;
    .gameBox{
        width: 100%;
        height: 14.5rem;
        position: relative;
        .gameTitle{
            width: 100%;
            height: 100%;
            padding: 0.26rem 0.24rem;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 0.46rem;
            color: #ffffff;
            line-height: 0.6rem;
        }
        .gameBg{
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
</style>

<template>
  <div class="container">
    <top :backShow='true' :backType='true'/>
    <div class="homeWrap">
		<div class="avatar">
            <div class="avatarBox">
                <img :src="userinfo.avatar" alt="" width="100%">
            </div>
            <div class="avatarEdit">
                <input type="file" accept="image/*" @change="inputFileChange" class="upbtn">
                <img src="@/assets/img/my/change_avatar.png" alt="change avatar" class="changeIcon">
            </div>
            
            <!-- <van-uploader class="avatarEdit" :after-read="afterRead" :max-count="1" :before-read="beforeRead" >
                <img src="@/assets/img/my/change_avatar.png" alt="change avatar" class="changeIcon">
            </van-uploader> -->
        </div>
        <div class="nickname">{{userinfo.nickname}}</div>
        <div class="changePassword" @click="change">Change password</div>
        <div class="myData">
            <div class="point">
                <div class="label">My points</div>
                <span>{{userinfo.score}}</span>
            </div>
            <div class="line"></div>
            <div class="refer">
                <div class="label">
                    <em>Refer a colleague</em>
                    <!-- <img src="@/assets/img/my/info.png" alt="info" class="infoIcon"> -->
                </div>
                <span>
                    <em>{{userinfo.referral_code}}</em>
                    <img src="@/assets/img/my/copy.png" alt="copy" class="copyIcon" v-clipboard:copy="userinfo.referral_code"  v-clipboard:success="onCopy" v-clipboard:error="onError">
                </span>
            </div>
        </div>
        <div class="list">
            <div class="listTitle">
                <img src="@/assets/img/my/ranking.png" alt="" class="rankIcon">
                <span>TOP 20</span>
            </div>
            <div class="listBox">
                <table>
                    <caption style="display:none;">leaderboard</caption>
                    <tr class="listHeader">
                        <th class="listWidth_01" scope="col" caption='leaderboard'>Username</th>
                        <th class="listWidth_02" scope="col" caption='leaderboard'>Points</th>
                        <th class="listWidth_03" scope="col" caption='leaderboard'>Ranking</th>
                    </tr>
                    <div class="listContent">
                        <tr class="listItem" v-for="(item,index) in rank" :key="index">
                            <td class="listWidth_01" scope="row">
                                <div class="listAvatar"><img :src="item.avatar" alt="" width="100%"></div>
                                <em>{{item.nickname}}</em>
                            </td>
                            <td class="listWidth_02">
                                {{item.score}}
                            </td>
                            <td class="listWidth_03">
                                {{item.rank}}
                            </td>
                        </tr>
                    </div>
                </table>
                <!-- <div class="listHeader">
                    <span class="listWidth_01">Username</span>
                    <span class="listWidth_02">Points</span>
                    <span class="listWidth_03">Ranking</span>
                </div>
                <div class="listContent">
                    <div class="listItem" v-for="(item,index) in rank" :key="index">
                        <span class="listWidth_01">
                            <div class="listAvatar"><img :src="item.avatar" alt="" width="100%"></div>
                            <em>{{item.username}}</em>
                        </span>
                        <span class="listWidth_02">{{item.score}}</span>
                        <span class="listWidth_03">{{item.rank}}</span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <tabbar/>
  </div>
</template>

<script>
import tabbar from '@/components/tabbar.vue'
import top from '@/components/top.vue'
export default {
    components:{
      tabbar,
      top
    },
    head(){
        return {
            title:''
        }
    },
    data(){
        return {
          userinfo:{},
          rank:[],
        }
    },
    
    mounted(){
        var that = this;
        that.getIndexInfo(0);
        that.getRank();
        utag_fun(that.$route.name)
    },
    methods:{
         inputFileChange (e){
            var that = this;
            if (e.target.files[0].type != 'image/jpeg' && e.target.files[0].type != 'image/png') {
                that.$toast('Please upload pictures in JPG or PNG format');
                return false;
            }
            let formData = new window.FormData();
            that.$toast.loading({
                message: 'Uploading...',
                forbidClick: true,
            })
            formData.append('file',e.target.files[0])
            that.$http.post(that.$api.upload.url, formData,{'content-type': 'multipart/form-data',}).then(res => {
                if(res.code == 1){
                    that.edit(res.data.url);
                }else{
                    that.$toast.clear()
                    that.$toast(res.msg);
                }
            }).catch(res=>{
                alert(res+'444')
            })
        },
        afterRead:function(file){
            var that = this;
            that.$toast.loading({
                message: 'Uploading...',
                forbidClick: true,
            })
            let formData = new window.FormData();
            formData.append('file',file.file)

            that.$http.post(that.$api.upload.url, formData,{'content-type': 'multipart/form-data',}).then(res => {
                if(res.code == 1){
                    that.edit(res.data.url);
                }else{
                    that.$toast.clear()
                    that.$toast(res.msg);
                }
            }).catch(res=>{
                alert(res+'444')
            })
        },
        beforeRead:function(file){
            var that = this;
            if (file.type != 'image/jpeg' && file.type != 'image/png') {
                that.$toast('Please upload pictures in JPG or PNG format');
                return false;
            }
            return true;
        },
        edit:function(url){
            var that = this;
            that.$http.post(that.$api.profile.url, {avatar:url}).then(res => {
                if(res.code == 1){
                    that.getIndexInfo(1);
                }else{
                    that.$toast.clear()
                    that.$toast(res.msg);
                }
            })
        },
        change:function(){
            var that = this;
            that.$router.push({path:'/my/change'})
        },
        getIndexInfo:function(type){
            var that = this;
            that.$http.get(that.$api.userIndex.url, {}).then(res => {
                if(res.code == 1){
                    that.$toast.clear()
                    that.userinfo = res.data.uinfo;
                    if(type == 1){
                        that.$toast.success('Upload successful');
                    }
                    
                }else{
                    that.$toast(res.msg);
                }
            })
        },
        getRank:function(){
            var that = this;
            that.$http.get(that.$api.getRank.url, {}).then(res => {
                if(res.code == 1){
                    that.rank = res.data.lists;
                }else{
                    that.$toast(res.msg);
                }
            })
        },
        onCopy:function(e){
            var that = this;
            that.$toast.success('Copied');
        },
        onError:function(e){
            var that = this;
            that.$toast.fail('Copy Fail');
        },
    },
}
</script>

<style lang='scss' scoped>
table{
    border-spacing: 0;
}
.homeWrap{
    width: 100%;
    padding-top: 0.4rem;
    box-sizing: border-box;
    .avatar{
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 auto;
        position: relative;
        .avatarBox{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
        }
        .avatarEdit{
            width: 0.38rem;
            height: 0.38rem;
            position: absolute;
            bottom: 0.09rem;
            right: 0.01rem;
            .changeIcon{
                display: block;
                width: 100%;
                height: 100%;
            }
            .upbtn{
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                opacity: 0;
            }
        }
    }
    .nickname{
        width: 100%;
        line-height: 0.6rem;
        font-weight: bold;
        font-size: 0.46rem;
        text-align: center;
    }
    .changePassword{
        width: 2.32rem;
        height: 0.41rem;
        border: 1px solid #BCBCBC;
        border-radius: 0.04rem;
        box-sizing: border-box;
        margin: 0.08rem auto 0;
        font-size: 0.19rem;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .myData{
        width: 100%;
        margin-top: 0.45rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .point{
            width: 3.75rem;
            .label{
                width: 100%;
                line-height: 0.36rem;
                font-size: 0.28rem;
                font-weight: bold;
                text-align: center;
            }
            >span{
                display: block;
                width: 100%;
                line-height: 0.58rem;
                font-size: 0.44rem;
                font-weight: bold;
                text-align: center;
                margin-top: 0.1rem;
            }
        }
        .line{
            width: 0.01rem;
            height: 0.69rem;
            background: #C3C3C3;
        }
        .refer{
            width: 3.74rem;
            .label{
                width: 100%;
                line-height: 0.36rem;
                display: flex;
                align-items: center;
                justify-content: center;
                >em{
                    font-size: 0.28rem;
                    font-weight: bold;
                }
                .infoIcon{
                    display: block;
                    width: 0.2rem;
                    height: 0.2rem;
                    margin-left: 0.08rem;
                    margin-top: 0.07rem;
                }
            }
            >span{
                display: flex;
                width: 100%;
                line-height: 0.58rem;
                margin-top: 0.1rem;
                align-items: center;
                justify-content: center;
                >em{
                    font-size: 0.28rem;
                    font-weight: bold;
                }
                .copyIcon{
                    display: block;
                    width: 0.25rem;
                    height: 0.25rem;
                    margin-left: 0.14rem;

                }
            }
        }
    }
    .list{
        width: 100%;
        margin-top: 0.8rem;
        .listTitle{
            width: 100%;
            height: 0.58rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .rankIcon{
                display: block;
                width: 0.43rem;
                height: 0.32rem;
            }
            >span{
                font-size: 0.44rem;
                font-weight: bold;
                margin-left: 0.18rem;
            }
        }
        .listBox{
            width: 100%;
            margin-top: 0.25rem;
            border-top: 1px solid #CACACA;
            .listWidth_01{
                width: 3.05rem;
            }
            .listWidth_02{
                width: 2.74rem;
            }
            .listWidth_03{
                width: 1.23rem;
            }
            .listHeader{
                width: 100%;
                height: 0.89rem;
                padding: 0 0.24rem;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                >th{
                    display: block;
                    font-size: 0.3rem;
                    font-weight: bold;
                    text-align: left;
                }
            }
            .listContent{
                width: 100%;
                .listItem{
                    width: 100%;
                    height: 0.86rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.24rem;
                    box-sizing: border-box;
                    background: #ffffff;
                    &:nth-child(odd){
                            background: #F2F2F2;
                        }
                    >td{
                        display: flex;
                        height: 100%;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 0.24rem;
                        .listAvatar{
                            width: 0.5rem;
                            height: 0.5rem;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                        >em{
                            margin-left: 0.17rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
